import { Box } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import TicketForm from "./components/TicketForm";

export default function CreateTicketPage() {
    const { eventId } = useParams();



    return (
        <Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
            <TicketForm eventId={eventId} />
        </Box>
    )
}