import { apiFetch } from "services/api";

export async function fetchMercadoPagoTokenStatus(): Promise<Response>{
    // Get token from sessionStorage
    const token = sessionStorage.getItem('token');

    // Fetch from api and pass token for authentication
    const response = await apiFetch(
        '/ecommerce/payment/mercadopago-tokens',
        {
            headers: {
                "Authorization": `Token ${token}`,
                'Content-Type': 'application/json'
        }
    });

    return response;
}