import { useEffect, useState } from "react";
import Product from "../../../models/Product";
import { fetchProduct } from "../services/fetchProduct";

export default function ProductHook(id: number) {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [product, setProduct] = useState<Product>();
    const [error, setError] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        fetchProduct(id)
            .then((data: Product) => {
                setProduct(data);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [id]);

    return { product, isLoading, error };
}