import { apiFetch } from "../../../../../../services/api";
import Promotion from "../../models/Promotion";

export async function fetchPromotion(promotionId: number): Promise<Promotion>{
    // Get token from sessionStorage
    const token = sessionStorage.getItem('token');

    // Fetch from api and pass token for authentication
    const response = await apiFetch(
        `/ecommerce/promotions/${promotionId}`,
        {
            headers: {
                "Authorization": `Token ${token}`
            }
        });

    const data = await response.json();

    const promotion: Promotion = {
        id: data.id,
        name: data.name,
        category: data.category,
        discount_percentage: data.discount_percentage,
        max_order_quantity: data.max_order_quantity,
        start_datetime_utc: data.start_datetime_utc ? new Date(data.start_datetime_utc) : null,
        end_datetime_utc: data.end_datetime_utc ? new Date(data.end_datetime_utc) : null,
        is_active: data.is_active,
        created_at: new Date(data.created_at),
        updated_at: new Date(data.updated_at),
    };

    return promotion;
}