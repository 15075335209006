import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import CompanyUsersTable from "./components/UsersTable";
import UsersHook from "./hooks/UsersHook";

export default function UsersPage() {

    const { users, isLoading, error } = UsersHook();

    return (
        <Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
            {
                (
                    isLoading
                    ? <Flex justifyContent='center' alignItems='center' height='100vh'>
                        <Spinner />
                    </Flex>
                    : error
                        ? <Flex justifyContent='center' alignItems='center' height='100vh'>
                            <Text>Ha ocurrido un error al pedir los usuarios. Intente de nuevo más tarde.</Text>
                        </Flex>
                        : <CompanyUsersTable companyUsers={users} />
                )
            }
        </Box>
    );
}