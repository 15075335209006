import { apiFetch } from "../../../../../../services/api";
import ItemCut from "../../models/ItemCut";


export async function fetchProductCuts(
    eventId: number
): Promise<ItemCut[]>{
    // Get token from sessionStorage
    const token = sessionStorage.getItem('token');

    // Fetch from api and pass token for authentication
    const response = await apiFetch(
        `/products/ordered-products-cuts/${eventId}/count`,
        {
            headers: {
                "Authorization": `Token ${token}`
        }
    });

    const data = (await response.json());

    const productCuts: ItemCut[] = data.map((itemCut: any) => {
        return {
            userDescription: itemCut.user_description,
            itemDescription: itemCut.item_description,
            itemType: itemCut.item_type,
            count: itemCut.count,
        }
    });

    return productCuts;
}