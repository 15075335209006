// Chakra imports
import { Button, Flex, Icon, Text, useColorModeValue, useToast } from '@chakra-ui/react';
import { MdConfirmationNumber, MdDelete } from 'react-icons/md';
// Custom components
import Card from 'components/card/Card';
import { Invite } from '../../models/Invite';
import useDeleteInvite from '../hooks/useDeleteInvite';
import { useEffect } from 'react';


export default function InviteItem(props: {
	invite: Invite
}) {
	const { invite } = props;

    const toast = useToast();

    const { deleteInvite, deleted, isLoading, error } = useDeleteInvite(invite.id);

	// Chakra Color Mode
	const textColor = useColorModeValue('brands.900', 'white');
	const bgItem = useColorModeValue(
		{ bg: 'white', boxShadow: '0px 40px 58px -20px rgba(112, 144, 176, 0.12)' },
		{ bg: 'navy.700', boxShadow: 'unset' }
	);

    // const textColor = useColorModeValue('secondaryGray.900', 'white');
    const iconColor = useColorModeValue('red.400', 'red.400');
	const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
	const bgHover = useColorModeValue({ bg: 'secondaryGray.400' }, { bg: 'whiteAlpha.50' });
	const bgFocus = useColorModeValue({ bg: 'secondaryGray.300' }, { bg: 'whiteAlpha.100' });

    const user = invite.user.split('_')[1];
    // Invite has a ticket field, group by ticket and count
    const groupedByTicket = invite.tickets.reduce((acc: { [key: string]: number }, ticket) => {
        acc[ticket] = (acc[ticket] || 0) + 1;
        return acc;
    }, {});

    useEffect(() => {
        if (error && !isLoading && !deleted) {
            toast({
                title: 'Error al eliminar la invitación',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top-right'
            });
        } else if (!error && !isLoading && deleted) {
            toast({
                title: 'Invitación eliminada',
                status: 'success',
                duration: 3000,
                isClosable: true,
                position: 'top-right'
            });
            // Reload page
            window.location.reload();
        }
    }, [error, deleted, isLoading, toast]);


	return (
		<Card _hover={bgItem} bg='transparent' boxShadow='unset' px='24px' py='21px' transition='0.2s linear'>
			<Flex direction={{ base: 'column' }} justify='center'>
				<Flex position='relative' align='center'>
                    <Icon as={MdConfirmationNumber} color={textColor} boxSize='20px' me='16px' />
                    <Flex
						direction='column'
						w={{ base: '70%', md: '100%' }}
						me={{ base: '4px', md: '32px', xl: '10px', '3xl': '32px' }}>
						<Text
							color={textColor}
							fontSize={{
								base: 'md'
							}}
							mb='5px'
							fontWeight='bold'
							me='14px'>
							{user}
						</Text>
                        {
                            Object.entries(groupedByTicket).map(([ticket, count]) => (
                                <Text
                                    color='secondaryGray.600'
                                    fontSize={{
                                        base: 'sm'
                                    }}
                                    fontWeight='400'
                                    me='14px'
                                    key={ticket}
                                    >
                                    {count}X {ticket}
                                </Text>
                            ))
                        }
					</Flex>
					<Flex me={{ base: '4px', md: '32px', xl: '10px', '3xl': '32px' }} align='center'>
						<Button
							alignItems='center'
							justifyContent='center'
                            isLoading={isLoading}
							bg={bgButton}
							_hover={bgHover}
							_focus={bgFocus}
							_active={bgFocus}
							w='37px'
							h='37px'
							lineHeight='100%'
							borderRadius='10px'
							onClick={async () => {
                                await deleteInvite(invite.id);
                            }}
							>
							<Icon as={MdDelete} color={iconColor} w='24px' h='24px' />
						</Button>
					</Flex>
				</Flex>
			</Flex>
		</Card>
	);
}
