import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import ProductForm from "../components/ProductForm";
import CreateProductHook from "./hooks/CreateProductHook";

export default function CreateProductPage() {
    const {
        categories,
        isLoading,
        error
    } = CreateProductHook();

    // get eventId from path
    const { eventId } = useParams();

    const parsedEventId = parseInt(eventId);

    return (
        <Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
            {
                (
                    isLoading
                    ? <Flex justifyContent='center' alignItems='center' height='100vh'>
                        <Spinner />
                    </Flex>
                    : error
                        ? <Flex justifyContent='center' alignItems='center' height='100vh'>
                            <Text>Ha ocurrido un error al pedir los productos. Intente de nuevo más tarde.</Text>
                        </Flex>
                        : <ProductForm product={null} eventId={parsedEventId} categories={categories} />
                )
            }
        </Box>
    );
}