import { apiFetch } from "services/api";

export async function submitVenueApi(data: FormData, id?: number): Promise<void> {
    const token = sessionStorage.getItem('token');

    const response = await apiFetch(
        '/eventos/predios' + (id ? `/${id}` : ''),
        {
            method: id ? 'PUT' : 'POST',
            headers: {  
                "Authorization": `Token ${token}`,
            },
            body: data
    });

    if (!response.ok) {
        throw new Error('Error al guardar el predio');
    }
}