import { apiFetch } from "services/api";


export async function loginUser(username: string, password: string): Promise<Response> {
    try {

        const data = {
            username: `empresa_+54${username}`,
            password,
        };

        // Fetch from api and pass token for authentication
        const response = await apiFetch(
            '/auth/dashboard/login',
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            }
        );

        return response;
    } catch (error) {
        throw error;
    }
}