import { Box } from '@chakra-ui/react'
import { useParams } from 'react-router-dom';
import InviteForm from './components/InviteForm';

export default function CreateInvitePage() {

    const { id } = useParams();

    return (
        <Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
            <InviteForm eventId={id} />
        </Box>
    )
}