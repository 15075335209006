import { Icon } from '@chakra-ui/react';
import { MdHome, MdCalendarMonth, MdGroup, MdStadium, MdMonetizationOn } from 'react-icons/md';

// Admin Imports
import MainDashboard from 'views/admin/reports';
import Events from 'views/admin/events';
import UsersPage from 'views/admin/users';
import VenuesPage from 'views/admin/venues';
import FinancePage from 'views/admin/finance';

const routes = [
	{
		name: 'Reportes',
		path: '/admin',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		component: MainDashboard
	},
	{
		name: 'Eventos',
		path: '/admin/eventos',
		icon: <Icon as={MdCalendarMonth} width='20px' height='20px' color='inherit' />,
		component: Events,
		secondary: true,
	},
	{
		name: 'Usuarios',
		path: '/admin/usuarios',
		icon: <Icon as={MdGroup} width='20px' height='20px' color='inherit' />,
		component: UsersPage,
		secondary: true,
	},
	{
		name: 'Predios',
		path: '/admin/predios',
		icon: <Icon as={MdStadium} width='20px' height='20px' color='inherit' />,
		component: VenuesPage,
		secondary: true,
	},
	{
		name: 'Finanzas',
		path: '/admin/finanzas',
		icon: <Icon as={MdMonetizationOn} width='20px' height='20px' color='inherit' />,
		component: FinancePage,
		secondary: true,
	},
	// {
	// 	name: 'Sign In',
	// 	path: '/auth/sign-in',
	// 	icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
	// 	component: SignInCentered
	// }
];

export default routes;
