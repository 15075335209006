import { apiFetch } from "../../../../services/api";
import Predio from "../pages/models/Predio";

export async function fetchPredios(): Promise<Predio[]>{
    // Get token from sessionStorage
    const token = sessionStorage.getItem('token');

    // Fetch from api and pass token for authentication
    const response = await apiFetch(
        '/eventos/predios',
        {
            headers: {
                "Authorization": `Token ${token}`
            }
        });

    const data = await response.json();

    return data as Predio[];
}