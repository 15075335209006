import { apiFetch } from "services/api";
import Ticket from "../../../models/Ticket";


export async function fetchTicket(id: number): Promise<Ticket>{
    // Get token from sessionStorage
    const token = sessionStorage.getItem('token');

    // Fetch from api and pass token for authentication
    const response = await apiFetch(
        `/entradas/${id}`,
        {
            headers: {
                "Authorization": `Token ${token}`
            }
        });

    const data = await response.json();

    return data as Ticket;
}