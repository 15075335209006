import { apiFetch } from "../../../../../../services/api";
import Product from "../../products/models/Product";

export async function fetchProductsApi(
    eventId: number
): Promise<Product[]>{
    // Get token from sessionStorage
    const token = sessionStorage.getItem('token');

    // Fetch from api and pass token for authentication
    const response = await apiFetch(
        `/products?event=${eventId}`,
        {
            headers: {
                "Authorization": `Token ${token}`
        }
    });

    const data = (await response.json())['results'];

    const products: Product[] = data.map((product: any) => {
        return {
            id: product.id,
            name: product.name,
            price: product.price,
            stock: product.stock,
            quantity: product.quantity,
            description: product.description,
            minimumAge: product.minimum_age,
            image: product.image,
            category: product.category,
        }
    });

    return products;
}