import React, { useRef, useState, useEffect } from 'react';
import { Box, Button, Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, useMediaQuery, useToast } from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import {
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable
} from '@tanstack/react-table';
import Card from 'components/card/Card';
import Papa from 'papaparse';
import * as Yup from 'yup';
import { parse, format, isValid } from 'date-fns';
import useNoUserFormSubmit from "../hooks/useNoUserFormSubmit";
import NoUserInvite from '../../models/NoUserInvite';

interface NoUserInviteTableProps {
    eventId: number;
    noUserInvites: NoUserInvite[];
}

const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('Nombre es requerido'),
    last_name: Yup.string().required('Apellido es requerido'),
    id_number: Yup.string().required('Número de identificación es requerido'),
    date_of_birth: Yup.string().required('Fecha de nacimiento es requerida'),
    sex: Yup.string().required('Sexo es requerido'),
    phone: Yup.string().required('Celular es requerido'),
    email: Yup.string().email('Correo electrónico no válido').required('Correo electrónico es requerido'),
    ticket_type: Yup.string().required('Nombre de la entrada es requerido'),
});

export default function NoUserInviteTable(props: NoUserInviteTableProps) {
    const navigate = useNavigate();
    const { eventId, noUserInvites } = props;
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const [isMobile] = useMediaQuery("(max-width: 480px)");
    const fileInputRef = useRef<HTMLInputElement>(null);
    const toast = useToast();
    const { submitForm, loading, error, submitted } = useNoUserFormSubmit(eventId);

    useEffect(() => {
        if (error) {
            toast({
                title: 'Error al cargar los datos',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top-right'
            });
        } else if (!loading && !error && submitted) {
            toast({
                title: 'Datos cargados exitosamente',
                status: 'success',
                duration: 3000,
                isClosable: true,
                position: 'top-right'
            });
            // reload current page
            // sleep 1 second
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
    }, [error, eventId, loading, navigate, submitted, toast]);

    const handleFileUpload = async (data: any) => {
        // Process the CSV data
        const parsedData = data.map((row: any) => {
            const dateOfBirth = parse(row['FECHA DE NACIMIENTO'], 'dd/MM/yyyy', new Date());
            const sexMap: { [key: string]: string } = {
                'Masculino': 'M',
                'Femenino': 'F',
                'Otro': 'X'
            };
            return {
                event: eventId,
                first_name: row['NOMBRE'],
                last_name: row['APELLIDO'],
                id_number: row['DNI'],
                date_of_birth: isValid(dateOfBirth) ? format(dateOfBirth, 'yyyy-MM-dd') : null,
                sex: sexMap[row['SEXO']] || row['SEXO'],
                phone: row['CELULAR'],
                email: row['EMAIL'],
                ticket_type: row['TIPO ENTRADA'],
            };
        });

        // Validate the parsed data
        const isValidData = validateData(parsedData);
        if (isValidData) {
            console.log('Data is valid');
            const uppercasedData = parsedData.map((item: any) => {
                const uppercasedItem: any = {};
                for (const key in item) {
                    if (item.hasOwnProperty(key) && typeof item[key] === 'string') {
                        uppercasedItem[key] = item[key].toUpperCase();
                    } else {
                        uppercasedItem[key] = item[key];
                    }
                }
                return uppercasedItem;
            });
            console.log('Uppercased Data:', uppercasedData);
            await submitForm(uppercasedData);
        } else {
            console.error('Data is invalid');
            toast({
                title: 'Error',
                description: 'Los datos del archivo CSV no son válidos.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            // Reset the file input value to allow re-uploading the same file
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    };

    const validateData = (data: any) => {
        try {
            data.forEach((item: any) => {
                validationSchema.validateSync(item, { abortEarly: false });
            });
            return true;
        } catch (error) {
            console.error('Validation error:', error);
            return false;
        }
    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <Card flexDirection='column' w='100%' mb='1rem' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
            <Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
                <Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
                    Invitaciones especiales
                </Text>
                <Button
                    variant='brand'
                    fontSize='sm'
                    onClick={handleButtonClick}
                >
                    {isMobile ? "+" : "+ Subir csv"}
                </Button>
            </Flex>
            <input
                type="file"
                accept=".csv"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={(event) => {
                    const file = event.target.files?.[0];
                    if (file) {
                        Papa.parse(file, {
                            header: true,
                            complete: (results) => {
                                handleFileUpload(results.data);
                            },
                        });
                    }
                }}
            />
            <NoUserInviteDataTable noUserInvites={noUserInvites} />
        </Card>
    );
}

const columnHelper = createColumnHelper<NoUserInvite>();

function NoUserInviteDataTable(props: { noUserInvites: NoUserInvite[] }) {
    const navigate = useNavigate();
	const { noUserInvites } = props;
	const [ sorting, setSorting ] = useState<SortingState>([]);
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
    const [isMobile] = useMediaQuery("(max-width: 480px)");


    const columns = [
		columnHelper.accessor((row) => row.first_name + ' ' + row.last_name, {
			id: 'full_name',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Nombre y apellido
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Text color={textColor} fontSize='sm' fontWeight='700'>
						{info.getValue()}
					</Text>
				</Flex>
			)
		}),
        columnHelper.accessor('ticket_type', {
			id: 'ticket_type',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Tipo de entrada
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Text color={textColor} fontSize='sm' fontWeight='700'>
						{info.getValue()}
					</Text>
				</Flex>
			)
		}),
        columnHelper.accessor('id_number', {
			id: 'id_number',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					DNI
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Text color={textColor} fontSize='sm' fontWeight='700'>
						{info.getValue()}
					</Text>
				</Flex>
			)
		}),
        columnHelper.accessor('date_of_birth', {
			id: 'date_of_birth',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Fecha de nacimiento
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Text color={textColor} fontSize='sm' fontWeight='700'>
						{info.getValue()}
					</Text>
				</Flex>
			)
		}),
        columnHelper.accessor('sex', {
			id: 'sex',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Sexo
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Text color={textColor} fontSize='sm' fontWeight='700'>
						{info.getValue()}
					</Text>
				</Flex>
			)
		}),
        columnHelper.accessor('phone', {
			id: 'phone',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Celular
				</Text>
			),
			cell: (info: any) => (
                <Flex align='center'>
                    <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {info.getValue()}
                    </Text>
                </Flex>
			)
		}),
        columnHelper.accessor('email', {
            id: 'email',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color='gray.400'>
                    Correo electrónico
                </Text>
            ),
            cell: (info: any) => (
                <Flex align='center'>
                    <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {info.getValue()}
                    </Text>
                </Flex>
            )
        }),
    ];

    const table = useReactTable({
		data: noUserInvites,
		columns,
		state: {
			sorting
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		debugTable: true
	});
    return (
        <Box>
            <Table variant='simple' color='gray.500' mb='24px' mt="12px">
                <Thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <Tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <Th
                                        key={header.id}
                                        colSpan={header.colSpan}
                                        pe='10px'
                                        borderColor={borderColor}
                                        cursor='pointer'
                                        onClick={header.column.getToggleSortingHandler()}>
                                        <Flex
                                            justifyContent='space-between'
                                            align='center'
                                            fontSize={{ sm: '10px', lg: '12px' }}
                                            color='gray.400'>
                                            {flexRender(header.column.columnDef.header, header.getContext())}{{
                                                asc: '',
                                                desc: '',
                                            }[header.column.getIsSorted() as string] ?? null}
                                        </Flex>
                                    </Th>
                                );
                            })}
                        </Tr>
                    ))}
                </Thead>
                <Tbody>
                    {table.getRowModel().rows.slice(0, 11).map((row) => {
                        return (
                            <Tr key={row.id}>
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <Td
                                            key={cell.id}
                                            fontSize={{ sm: '14px' }}
                                            minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                                            borderColor='transparent'>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </Td>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
        </Box>
    );
}