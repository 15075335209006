import { apiFetch } from "../../../../../../services/api";

export async function postEvent(formData: FormData): Promise<void> {
    try {
        // Get token from sessionStorage
        const token = sessionStorage.getItem('token');

        // Fetch from api and pass token for authentication
        const response = await apiFetch(
            '/eventos/',
            {
                method: 'POST',
                headers: {  
                    "Authorization": `Token ${token}`,
                    // "Content-Type": "multipart/form-data"
                },
                body: formData
            }
        );

        if (!response.ok) {
            throw new Error(response.statusText);
        }


        return;
    } catch (error) {
        throw error;
    }
}