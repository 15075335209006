// Chakra imports
import {
    Box,
    Button,
    Flex,
    Icon,
    Text,
    useColorModeValue
} from '@chakra-ui/react';
import Card from 'components/card/Card';
// Custom components
import LineChart from 'components/charts/LineChart';
import React from 'react';
import { MdBarChart } from 'react-icons/md';

export interface TotalSalesProps {
    xSerie: any[];
    ySeries: any[];
    title: string;
    [x: string]: any;
}


export default function TotalSales(props: TotalSalesProps) {
	const { xSerie, ySeries, title, ...rest } = props;

	// Chakra Color Mode
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const iconColor = useColorModeValue('brand.500', 'white');
	const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
	const bgHover = useColorModeValue({ bg: 'secondaryGray.400' }, { bg: 'whiteAlpha.50' });
	const bgFocus = useColorModeValue({ bg: 'secondaryGray.300' }, { bg: 'whiteAlpha.100' });

    const chartOptions = getChartOptions(xSerie);

	return (
		<Card alignItems='center' flexDirection='column' w='100%' {...rest}>
			<Flex align='center' w='100%' px='15px' py='10px'>
				<Text me='auto' color={textColor} fontSize='xl' fontWeight='700' lineHeight='100%'>
					{title}
				</Text>
				<Button
					alignItems='center'
					justifyContent='center'
					bg={bgButton}
					_hover={bgHover}
					_focus={bgFocus}
					_active={bgFocus}
					w='37px'
					h='37px'
					lineHeight='100%'
					borderRadius='10px'
					{...rest}>
					<Icon as={MdBarChart} color={iconColor} w='24px' h='24px' />
				</Button>
			</Flex>
            <Box h='240px' mt='auto' w='100%'>
                {
                    (xSerie.length === 0)
                    ? <Flex justifyContent='center' alignItems='center' h='100%'>
                        <Text color={textColor} fontSize='xl' fontWeight='700' lineHeight='100%'>
                            No hay datos para mostrar.
                        </Text>
                    </Flex>
				    : <LineChart chartData={ySeries} chartOptions={chartOptions} />
                }
			</Box>
		</Card>
	);
}


function getChartOptions(xSerie: any[]) {
    return {
        chart: {
            toolbar: {
                show: false
            },
            dropShadow: {
                enabled: true,
                top: 13,
                left: 0,
                blur: 10,
                opacity: 0.1,
                color: '#4318FF'
            }
        },
        colors: [ '#4318FF', '#39B8FF' ],
        markers: {
            size: 0,
            colors: 'white',
            strokeColors: '#7551FF',
            strokeWidth: 3,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [] as any[],
            shape: 'circle',
            radius: 2,
            offsetX: 0,
            offsetY: 0,
            showNullDataPoints: true
        },
        tooltip: {
            theme: 'dark'
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            type: 'line'
        },
        xaxis: {
            type: 'numeric',
            categories: xSerie,
            labels: {
                style: {
                    colors: '#A3AED0',
                    fontSize: '12px',
                    fontWeight: '500'
                }
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            }
        },
        yaxis: {
            show: false
        },
        legend: {
            show: false
        },
        grid: {
            show: false,
            column: {
                color: [ '#7551FF', '#39B8FF' ],
                opacity: 0.5
            }
        },
        color: [ '#7551FF', '#39B8FF' ]
    };    
}