import {
    useToast,
    FormControl,
    FormLabel,
    Input,
    Button,
    FormHelperText,
} from "@chakra-ui/react";
import { Formik, Form, Field, FieldProps } from 'formik';
import Card from "components/card/Card";
import { generateMercadoPagoOauthUrl } from "utils/MercadoPagoOauth";


interface MercadoPagoTokenFormValues {
    [key: string]: any;
    description: string;
}

export default function MercadoPagoTokenForm() {

    const handleOnSubmit = async (data: MercadoPagoTokenFormValues) => {
        redirectToMercadoPagoOauth(data.description);
    }

    const initialValues: MercadoPagoTokenFormValues = {
        description: ''
    };

    const redirectToMercadoPagoOauth = (description: string) => {
        const mercadoPagoOauthUrl: string = generateMercadoPagoOauthUrl(description);
        // redirect to mercado pago oauth url
        window.location.href = mercadoPagoOauthUrl;
    }

    return (
        <Card>
            <Formik
                initialValues={initialValues}
                onSubmit={handleOnSubmit}
            >
                {(props) => (
                    <Form>
                        <Field name='description'>
                            {({ field }: FieldProps) => (
                                <FormControl isRequired={true} mb="1rem">
                                    <FormLabel htmlFor='description'>Nombre de referencia</FormLabel>
                                    <Input {...field} id='description' maxLength={32} />
                                    <FormHelperText>
                                        {field.value?.length ?? 0}/32 caracteres
                                    </FormHelperText>
                                </FormControl>
                            )}
                        </Field>
                        <Button
                            type='submit'
                            colorScheme='brand'
                            size='lg'
                            fontSize='md'
                            fontWeight='500'
                            borderRadius='15px'
                            mx={{ base: "auto", lg: "unset" }}
                            me='auto'
                            mb={{ base: "20px", md: "auto" }}
                            >
                                Conectar cuenta de Mercado Pago
                        </Button>
                    </Form>
                )}
            </Formik>
        </Card>
    )


}