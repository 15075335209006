import { useEffect, useState } from "react";
import Ticket from "../../../models/Ticket";
import { fetchTicket } from "../services/fetchTicket";

export default function TicketHook(ticketId: number) {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [ticket, setTicket] = useState<Ticket>();
    const [error, setError] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        fetchTicket(ticketId)
        .then((data: Ticket) => {
            setTicket(data);
        })
        .catch((error) => {
            setError(error);
        })
        .finally(() => {
            setIsLoading(false);
        });
    }, [ticketId]);

    return { ticket, isLoading, error };
}