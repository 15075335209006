import { useState } from "react";
import {
    Box,
    Button,
    Flex,
    Icon,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useMediaQuery,
    Badge
} from '@chakra-ui/react';
import {
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable
} from '@tanstack/react-table';
import { MdCancel, MdCheckCircle } from 'react-icons/md';
import Card from 'components/card/Card';
import { useNavigate } from "react-router-dom";
import Promotion from "../../models/Promotion";
import PromotionItem from "../../models/PromotionItem";


interface PromotionTableProps {
	eventId: number;
    promotions: Promotion[];
}

const columnHelper = createColumnHelper<Promotion>();

export default function PromotionTable (props: PromotionTableProps) {
    const navigate = useNavigate();
	const { eventId, promotions } = props;
	const [ sorting, setSorting ] = useState<SortingState>([]);
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
    const [isMobile] = useMediaQuery("(max-width: 480px)");

    const columns = [
		// columnHelper.accessor('category', {
		// 	id: 'category',
		// 	header: () => (
		// 		<Text
		// 			justifyContent='space-between'
		// 			align='center'
		// 			fontSize={{ sm: '10px', lg: '12px' }}
		// 			color='gray.400'>
		// 			Categoria
		// 		</Text>
		// 	),
		// 	cell: (info: any) => (
		// 		<Flex align='center'>
		// 			<Badge color='green' fontSize='sm' fontWeight='700'>
		// 				{info.getValue()}
		// 			</Badge>
		// 		</Flex>
		// 	)
		// }),
		columnHelper.accessor('name', {
			id: 'name',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Titulo
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Text color={textColor} fontSize='sm' fontWeight='700'>
						{info.getValue()}
					</Text>
				</Flex>
			)
		}),
        columnHelper.accessor('discount_percentage', {
			id: 'discount_percentage',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Porcentaje de descuento
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Text color={textColor} fontSize='sm' fontWeight='700'>
                        {info.getValue() * 100}%
					</Text>
				</Flex>
			)
		}),
        columnHelper.accessor('max_order_quantity', {
			id: 'max_order_quantity',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Cantidad máxima
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Text color={textColor} fontSize='sm' fontWeight='700'>
						{info.getValue()}
					</Text>
				</Flex>
			)
		}),
        columnHelper.accessor('tickets', {
			id: 'tickets',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Entradas
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Text color={textColor} fontSize='sm' fontWeight='700'>
						{info.getValue().map((promotionItem: PromotionItem) => {
                            if (typeof promotionItem.ticket !== 'number') {
                                return promotionItem.ticket.tipo + ' x' + promotionItem.quantity;
                            }
							return '';
						}).join(', ')}
                    </Text>
				</Flex>
			)
		}),
        columnHelper.accessor('products', {
			id: 'products',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Productos
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Text color={textColor} fontSize='sm' fontWeight='700'>
						{info.getValue().map((promotionItem: PromotionItem) => {
                            if (typeof promotionItem.product !== 'number') {
                                return promotionItem.product.name + ' x' + promotionItem.quantity;
                            }
							return '';
						}).join(', ')}
                    </Text>
				</Flex>
			)
		}),
        columnHelper.accessor('is_active', {
			id: 'is_active',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Habilitado?
				</Text>
			),
			cell: (info: any) => (
				<Icon
					w='24px'
					h='24px'
					me='5px'
					color={
						info.getValue()
						? 'green.500'
						: 'red.500'
					}
					as={
						info.getValue()
						? MdCheckCircle
						: MdCancel
					}
				/>
			)
		}),
        columnHelper.accessor('updated_at', {
			id: 'updated_at',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Última actualización
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Text color={textColor} fontSize='sm' fontWeight='700'>
						{new Date(info.getValue()).toLocaleDateString('es-ES', {
							year: 'numeric',
							month: 'long',
							day: 'numeric'
						})}
					</Text>
				</Flex>
			)
		}),
		// Show a button to get more details of the event
        columnHelper.accessor('id', {
            id: 'editPromotion',
            header: () => (
                <Text
                justifyContent='space-between'
                align='center'
                fontSize={{ sm: '10px', lg: '12px' }}
                color='gray.400'>
                
                </Text>
            ),
            cell: (info) => {
                const productId = info.getValue();
            
                return (
					<Button
						variant='brand'
						fontSize='sm'
						onClick={() => {
							navigate(`/admin/eventos/${eventId}/promociones/${productId}/editar`);
							}
						}
					>
						Editar
					</Button>
                );
            }
        }),
    ];

    const table = useReactTable({
		data: promotions,
		columns,
		state: {
			sorting
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		debugTable: true
	});
    return (
        <Card flexDirection='column' w='100%' mb='1rem' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
				<Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
					Mis promociones
				</Text>
                <Button
                    variant='brand'
                    fontSize='sm'
                    onClick={() => {
                        navigate(`/admin/eventos/${eventId}/promociones/crear`);

                        }
                    }
                >
                    {isMobile ? "+" : "+ Nueva promoción"}
                </Button>
			</Flex>
			<Box>
				<Table variant='simple' color='gray.500' mb='24px' mt="12px">
					<Thead>
						{table.getHeaderGroups().map((headerGroup) => (
							<Tr key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									return (
										<Th
											key={header.id}
											colSpan={header.colSpan}
											pe='10px'
											borderColor={borderColor}
											cursor='pointer'
											onClick={header.column.getToggleSortingHandler()}>
											<Flex
												justifyContent='space-between'
												align='center'
												fontSize={{ sm: '10px', lg: '12px' }}
												color='gray.400'>
												{flexRender(header.column.columnDef.header, header.getContext())}{{
													asc: '',
													desc: '',
												}[header.column.getIsSorted() as string] ?? null}
											</Flex>
										</Th>
									);
								})}
							</Tr>
						))}
					</Thead>
					<Tbody>
						{table.getRowModel().rows.slice(0, 11).map((row) => {
							return (
								<Tr key={row.id}>
									{row.getVisibleCells().map((cell) => {
										return (
											<Td
												key={cell.id}
												fontSize={{ sm: '14px' }}
												minW={{ sm: '150px', md: '200px', lg: 'auto' }}
												borderColor='transparent'>
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</Td>
										);
									})}
								</Tr>
							);
						})}
					</Tbody>
				</Table>
			</Box>
		</Card>
    );
}