import { MercadoPagoToken } from "../models/MercadoPagoToken";
import { apiFetch } from "../../../../services/api";

export async function fetchMercadoPagoTokens(): Promise<MercadoPagoToken[]>{
    // Get token from sessionStorage
    const token = sessionStorage.getItem('token');

    // Fetch from api and pass token for authentication
    const response = await apiFetch(
        '/ecommerce/payment/mercadopago-tokens',
        {
            headers: {
                "Authorization": `Token ${token}`
        }
    });

    const data = await response.json();

    const results = data.results;

    // Parse data to MercadoPagoToken[]
    return results as MercadoPagoToken[];
}