import { apiFetch } from "services/api";
import { Venue } from "../models/Venue";

export async function fetchVenues(): Promise<Venue[]> {
    const token = sessionStorage.getItem('token');
    const companyId = sessionStorage.getItem('empresaId');

    const response = await apiFetch(
        '/eventos/predios?empresa_id=' + companyId,
        {
            headers: {
                "Authorization": `Token ${token}`
        }
    });

    const data = await response.json();

    return data as Venue[];
}