import { Localidad } from "../models/Localidad";

export async function fetchLocalidadesApi(provinciaId: number): Promise<Localidad[]> {
    const response = await fetch(
        `https://apis.datos.gob.ar/georef/api/localidades?provincia=${provinciaId}&campos=nombre&max=5000`
    );

    const data = await response.json();

    const localidades = data.localidades as Localidad[];

    return localidades;
}