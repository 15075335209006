import { useEffect } from "react";
import { Formik, Form, Field, FieldProps, FieldArray, ErrorMessage} from "formik";
import {
    FormControl,
    FormLabel,
    Input,
    Button,
    NumberInput,
    NumberInputField,
    useToast,
    Text,
} from "@chakra-ui/react";
import Card from 'components/card/Card';
import { useNavigate } from "react-router-dom";
import Promotion from "../../../../models/Promotion";
import Ticket from "../../../../models/Ticket";
import Product from "../../../../products/models/Product";
import PromotionItem from "../../../../models/PromotionItem";
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { format, formatISO } from "date-fns";
import DateTimePicker from "./DateTimePicker";
import useFormSubmit from "../hooks/useFormSubmit";

export interface PromotionFormProps {
    promotion?: Promotion;
    eventId: number;
    tickets: Ticket[];
    products: Product[];
    [x: string]: any;
}

interface FormValues {
    [key: string]: any;
    name: string;
    tickets: PromotionItem[];
    products: PromotionItem[];
    category: string | number;
    discount_percentage: number;
    max_order_quantity: number;
    start_datetime_utc: Date | null;
    end_datetime_utc: Date | null;
    is_active: boolean;
}

export default function PromotionForm(props: PromotionFormProps) {

    const toast = useToast();

    const navigate = useNavigate();

    const { promotion, eventId, tickets, products } = props;

    const isUpdateMode = promotion !== null;

    const { submitForm, loading, error } = useFormSubmit(isUpdateMode ? 'update' : 'create', isUpdateMode ? promotion.id : null);

    const initialValues: FormValues = {
        event: eventId,
        name: '',
        category: 'PROMOTION',
        tickets: tickets.map(ticket => ({ category: 'TICKET', ticket: ticket.id, quantity: 0, product: null })),
        products: products.map(product => ({ category: 'PRODUCT', product: product.id, ticket: null, quantity: 0 })),
        discount_percentage: 0,
        max_order_quantity: 0,
        start_datetime_utc: null,
        end_datetime_utc: null,
        is_active: false,
    };

    if (isUpdateMode) {
        initialValues.name = promotion.name;
        initialValues.category = promotion.category;
        initialValues.tickets = promotion.tickets;
        initialValues.products = promotion.products;
        initialValues.discount_percentage = promotion.discount_percentage * 100;
        initialValues.max_order_quantity = promotion.max_order_quantity;
        initialValues.start_datetime_utc = promotion.start_datetime_utc;
        initialValues.end_datetime_utc = promotion.end_datetime_utc;
        initialValues.is_active = promotion.is_active;
    }

    // calculate the total amount based on quantity * price * discount
    const calculateTotalAmount = (values: FormValues): { subTotal: number; discountAmount: number; total: number; } => {
        let subTotal = 0;
        for (const ticket of values.tickets) {
            // search the ticket in the tickets array
            const ticketIndex = tickets.findIndex(t => t.id === ticket.ticket);
            if (ticketIndex === -1) {
                continue;
            }
            const ticketInArray = tickets[ticketIndex];
            subTotal += ticket.quantity * ticketInArray.precio;
        }
        for (const product of values.products) {
            // search the product in the products array
            const productIndex = products.findIndex(p => p.id === product.product);
            if (productIndex === -1) {
                continue;
            }
            const productInArray = products[productIndex];
            subTotal += product.quantity * productInArray.price;
        }
        const discountPercentage = values.discount_percentage / 100;
        const discountAmount = subTotal * discountPercentage;
        const total = subTotal - discountAmount;
        return {subTotal, discountAmount, total};
    };

    useEffect(() => {
        if (error) {
          toast({
            title: isUpdateMode ? 'Error al actualizar la promoción' : 'Error al crear la promoción',
            description: 'Por favor, intenta nuevamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      }, [error, isUpdateMode, toast]);
    
    //   const getChangedValues = (values: FormValues, event: any): Partial<FormValues> => {
    //     let changedValues: Partial<FormValues> = {};
    //     if (isUpdateMode) {
    //         for (const key in values) {
    //             if (values[key] !== (event as any)[key]) {
    //                 changedValues[key] = values[key];
    //             }
    //         }
    //     } else {
    //         changedValues = { ...values };
    //     }
    //     return changedValues;
    // };
    
    const getFormData = (values: Partial<FormValues>): FormData => {
        // const fieldMapping: { [key: string]: string } = {
        //     minimumAge: 'minimum_age',
        // };
    
        let formData = new FormData();
        for (const key in values) {
            if (key === 'start_datetime_utc' || key === 'end_datetime_utc') {
                if (values[key] !== null) {
                    formData.append(key, values[key].toISOString());
                }
            } else if (key === 'tickets' || key === 'products') {
                (values[key] as any[]).forEach((item, index) => {
                    formData.append(`${key}[${index}]`, JSON.stringify(item));
                });
            } else {
                formData.append(key, values[key]);
            }
        }
        //     // let adjustedKey = fieldMapping[key] || key;
        //     // if (changedValues[key] instanceof File) {
        //     //     formData.append(adjustedKey, changedValues[key], changedValues[key].name);
        //     // } else {
        //     //     formData.append(adjustedKey, changedValues[key]);
        //     // }
        // }
        return formData;
    };
    
    const onSubmit = async (values: FormValues) => {
        // const formattedValues = {
        //     ...values,
        //     start_datetime_utc: values.start_datetime_utc ? values.start_datetime_utc.toISOString() : null,
        //     end_datetime_utc: values.end_datetime_utc ? values.end_datetime_utc.toISOString() : null,
        // };
        // const changedValues = getChangedValues(values, promotion);
        values.tickets = values.tickets.filter(ticket => ticket.quantity > 0);
        values.products = values.products.filter(product => product.quantity > 0);
        const formData = getFormData(values);
        await submitForm(formData);

        if (!error) {
            // Show toast and then navigate
            toast({
                title: isUpdateMode ? 'Promoción actualizada' : 'Promoción creada',
                description: isUpdateMode ? 'La promoción se actualizó correctamente.' : 'La promoción se creó correctamente.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            // Wait for toast to disappear and then navigate
            navigate(`/admin/eventos/${eventId}`);
                    
        }
    };

    return (
        <Card>
            <Formik
                initialValues = {initialValues}
                onSubmit = {onSubmit}
            >
                {(props) => (
                    <Form>
                        <Field name='name'>
                            {({ field, form }: FieldProps) => (
                                <FormControl isRequired={ true } mb="1rem">
                                    <FormLabel htmlFor='name'>Nombre</FormLabel>
                                    <Input {...field} id='name' />
                                </FormControl>
                            )}
                        </Field>
                        <Field name='max_order_quantity'>
                            {({ field, form}: FieldProps) => (
                                <FormControl isRequired={ true } mb="1rem">
                                    <FormLabel htmlFor='max_order_quantity'>Cantidad máxima por compra</FormLabel>
                                    <NumberInput id='max_order_quantity' min={1} value={field.value} onChange={(valueString, valueNumber) => form.setFieldValue(field.name, valueNumber)}>
                                        <NumberInputField />
                                    </NumberInput>
                                </FormControl>
                            )}
                        </Field>
                        <Field name='discount_percentage'>
                            {({ field, form}: FieldProps) => (
                                <FormControl isRequired={ true } mb="1rem">
                                    <FormLabel htmlFor='discount_percentage'>Porcentaje de descuento</FormLabel>
                                    <NumberInput id='discount_percentage' min={0} max={90} value={field.value} onChange={(valueString, valueNumber) => form.setFieldValue(field.name, valueNumber)}>
                                        <NumberInputField />
                                    </NumberInput>
                                </FormControl>
                            )}
                        </Field>
                        <Field name='start_datetime_utc'>
                            {({ field, form }: FieldProps) => (
                                <FormControl mb="1rem">
                                    <FormLabel htmlFor='start_datetime_utc'>Fecha y hora de inicio</FormLabel>
                                    <Datetime
                                        value={field.value}
                                        onChange={(date) => form.setFieldValue(field.name, date)}
                                        renderInput={(props, openCalendar) => (
                                            <Input
                                                {...props}
                                                onClick={openCalendar}
                                                readOnly
                                                value={props.value ? format(new Date(props.value), 'yyyy-MM-dd HH:mm:ss') : ''}
                                            />
                                        )}
                                        inputProps={{ id: 'start_datetime_utc', readOnly: true }}
                                    />
                                </FormControl>
                            )}
                        </Field>
                        <Field name='end_datetime_utc'>
                            {({ field, form }: FieldProps) => (
                                <FormControl mb="1rem">
                                    <FormLabel htmlFor='end_datetime_utc'>Fecha y hora de fin</FormLabel>
                                    <Datetime
                                        value={field.value}
                                        onChange={(date) => form.setFieldValue(field.name, date)}
                                        renderInput={(props, openCalendar) => (
                                            <Input
                                                {...props}
                                                onClick={openCalendar}
                                                readOnly
                                                value={props.value ? format(new Date(props.value), 'yyyy-MM-dd HH:mm:ss') : ''}
                                            />
                                        )}
                                        inputProps={{ id: 'end_datetime_utc', readOnly: true }}
                                    />
                                </FormControl>
                            )}
                        </Field>
                        <Text fontSize="xl" fontWeight="bold" mt="2rem" mb="1rem">Entradas</Text>
                        <FieldArray name="tickets">
                            {({ push, remove }) => (
                                tickets.map((ticket, index) => (
                                    <Field name={`tickets[${index}].quantity`} type="number" min="1">
                                        {({ field, form }: FieldProps) => (
                                            <FormControl>
                                                <FormLabel htmlFor={`tickets[${index}].quantity`}>Elige cuantas {ticket.tipo}</FormLabel>
                                                <NumberInput min={1} max={ticket.cantidad}>
                                                    <NumberInputField {...field} id={`tickets[${index}].quantity`}/>
                                                </NumberInput>
                                                <ErrorMessage name={`tickets[${index}].quantity`} />
                                            </FormControl>
                                        )}
                                    </Field>
                                ))
                            )}
                        </FieldArray>
                        <Text fontSize="xl" fontWeight="bold" mt="2rem" mb="1rem">Productos</Text>
                        <FieldArray name="products">
                            {({ push, remove }) => (
                                products.map((product, index) => (
                                    <Field name={`products[${index}].quantity`} type="number" min="1">
                                        {({ field, form }: FieldProps) => (
                                            <FormControl>
                                                <FormLabel htmlFor={`products[${index}].quantity`}>Elige cuantas {product.name}</FormLabel>
                                                <NumberInput min={1} max={product.quantity}>
                                                    <NumberInputField {...field} id={`products[${index}].quantity`}/>
                                                </NumberInput>
                                                <ErrorMessage name={`products[${index}].quantity`} />
                                            </FormControl>
                                        )}
                                    </Field>
                                ))
                            )}
                        </FieldArray>
                        <Text fontSize="xl" fontWeight="bold" mt="2rem" mb="1rem">Resultado</Text>
                        <Text fontSize="lg" fontWeight="bold" mt="1rem" mb="1rem">Subtotal: {calculateTotalAmount(props.values).subTotal}</Text>
                        <Text fontSize="lg" fontWeight="bold" mt="1rem" mb="1rem">Descuento: {calculateTotalAmount(props.values).discountAmount}</Text>
                        <Text fontSize="lg" fontWeight="bold" mt="1rem" mb="1rem">Total: {calculateTotalAmount(props.values).total}</Text>
                        <Button
                            type='submit'
                            isLoading={loading}
                            colorScheme='brand'
                            size='lg'
                            fontSize='md'
                            fontWeight='500'
                            borderRadius='15px'
                            mx={{ base: "auto", lg: "unset" }}
                            me='auto'
                            mb={{ base: "20px", md: "auto" }}>
                            Crear promoción
                        </Button>

                    </Form>
                )}
            </Formik>
        </Card>
    );
}