// Chakra UI components
import {
    Box,
    Flex,
    Spinner,
    Text,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import { useParams } from 'react-router-dom';
import UpdateItem from './components/UpdateItem';
import TicketHook from './hooks/TicketHook';

export default function UpdateTicketPage()  {

    const { ticketId } = useParams();

    const { ticket, isLoading, error } = TicketHook(parseInt(ticketId));

    if (error) {
        return (
            <Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
                <Flex justifyContent='center' alignItems='center' height='100vh'>
                    <Text>Error al cargar la entrada</Text>
                </Flex>
            </Box>
        );
    }

    return (
        <Flex justifyContent='center' alignItems='center'>
            <Box pt={{ base: '180px', md: '80px', xl: '80px' }} w={{ base: '100%', md: '40%' }}>
                {
                    (
                        isLoading
                        ? <Flex justifyContent='center' alignItems='center' height='100vh'>
                            <Spinner />
                        </Flex>
                        : <Card>
                            <Flex align='center' justifyContent='center' w='100%' px='15px' py='10px'>
                                <Text fontSize='xl' fontWeight='700' lineHeight='100%'>
                                    Actualizar entrada
                                </Text>
                            </Flex>
                            <UpdateItem title='Nombre' field='tipo' type='text' value={ticket.tipo} maxLength={32} />
                            <UpdateItem title='Cantidad disponible' field='cantidad' type='number' value={ticket.cantidad} />
                            <UpdateItem title='Precio' field='precio' type='number' value={ticket.precio} />
                            <UpdateItem title='Limite por compra' field='limite' type='number' value={ticket.limite} />
                            <UpdateItem title='Privacidad' field='privacidad' type='text' value={ticket.privacidad} options={['PUBLICO', 'PRIVADO']} />
                            <UpdateItem title='Descripción' field='description' type='text' value={ticket.description} maxLength={128} />
                            
                        </Card>
                    )
                }
            </Box>
        </Flex>
    )
}