import { useState, useEffect } from 'react';
import Event from '../../models/Event';
import { Invite } from '../../models/Invite';
import Product from '../../products/models/Product';
import Ticket from '../../models/Ticket';
import { fetchEvent } from '../services/fetchEvent';
import { fetchInvites } from '../services/fetchInvites';
import { fetchProductsApi } from '../services/fetchProductsApi';
import { fetchTickets } from '../services/fetchTickets';
import { fetchTicketCuts } from '../services/fetchTicketCut';
import ItemCut from '../../models/ItemCut';
import { fetchProductCuts } from '../services/fetchProductCut';
import Promotion from '../../models/Promotion';
import { fetchPromotions } from '../services/fetchPromotions';
import NoUserInvite from '../../models/NoUserInvite';
import { fetchNoUserInvites } from '../services/fetchNoUserInvites';

export default function EventHook(id: number) {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [event, setEvent] = useState<Event>();
    const [tickets, setTickets] = useState<Ticket[]>([]);
    const [invites, setInvites] = useState<Invite[]>([]);
    const [products, setProducts] = useState<Product[]>([]);
    const [ticketCuts, setTicketCuts] = useState<ItemCut[]>([]);
    const [productCuts, setProductCuts] = useState<ItemCut[]>([]);
    const [promotions, setPromotions] = useState<Promotion[]>([]);
    const [noUserInvite, setNoUserInvite] = useState<NoUserInvite[]>();

    useEffect(() => {
        setIsLoading(true);
        fetchEvent(id).then((data: Event) => {
            setEvent(data);
        });
        fetchTickets(id).then((data: Ticket[]) => {
            setTickets(data);
        });
        fetchInvites(id).then((data: Invite[]) => {
            setInvites(data);
        });
        fetchProductsApi(id).then((data: Product[]) => {
            setProducts(data);
        });
        fetchTicketCuts(id).then((data: any) => {
            setTicketCuts(data);
        });
        fetchProductCuts(id).then((data: any) => {
            setProductCuts(data);
        });
        fetchPromotions(id).then((data: Promotion[]) => {
            setPromotions(data);
        });
        fetchNoUserInvites(id).then((data: NoUserInvite[]) => {
            setNoUserInvite(data);
        });

        setIsLoading(false);
    }, [id]);

    return { isLoading, event, tickets, invites, products, ticketCuts, productCuts, promotions, noUserInvite };
}