import { apiFetch } from '../../../../services/api';
import { AgeCount } from '../models/AgeCount';

export async function fetchAgeCounts(): Promise<AgeCount[]>{
    // Get token from sessionStorage
    const token = sessionStorage.getItem('token');

    // Fetch from api and pass token for authentication
    const response = await apiFetch(
        '/analytics/demo/age',
        {
            headers: {
                "Authorization": `Token ${token}`
        }
    });

    const data = await response.json();

    // Parse data to GenderCount[]
    return data;
}