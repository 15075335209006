import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import MercadoPagoTokensTable from "./components/MercadoPagoTokensTable";
import MercadoPagoTokensHook from "./hooks/MercadoPagoTokensHook";

export default function FinancePage() {
    const { mercadoPagoTokens, isLoading, error } = MercadoPagoTokensHook();

    return (
        <Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
            {
                (
                    isLoading
                    ? <Flex justifyContent='center' alignItems='center' height='100vh'>
                        <Spinner />
                    </Flex>
                    : error
                        ? <Flex justifyContent='center' alignItems='center' height='100vh'>
                            <Text>Ha ocurrido un error al pedir los predios. Intente de nuevo más tarde.</Text>
                        </Flex>
                        : <MercadoPagoTokensTable mercadoPagoTokens={mercadoPagoTokens} />
                )
            }
        </Box>
    );
}