import { useState, useEffect } from 'react';
import { fetchProductsApi } from 'views/admin/events/pages/details/services/fetchProductsApi';
import { fetchTickets } from 'views/admin/events/pages/details/services/fetchTickets';
import Ticket from 'views/admin/events/pages/models/Ticket';
import Product from 'views/admin/events/pages/products/models/Product';

export default function CreatePromotionHook(eventId: number) {
    const [tickets, setTickets] = useState<Ticket[]>([]);
    const [products, setProducts] = useState<Product[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setError(null);
            try {
                const [ticketsData, productsData] = await Promise.all([
                    fetchTickets(eventId),
                    fetchProductsApi(eventId),
                ]);
                setTickets(ticketsData);
                setProducts(productsData);
            } catch (err) {
                setError(err as Error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [eventId]);

    return { tickets, products, isLoading, error };
}