import { useState, useEffect } from 'react';
import { Localidad } from '../models/Localidad';
import { Provincia } from '../models/Provincia';
import { fetchLocalidadesApi } from '../services/fetchLocalidadesApi';
import { fetchProvinciasApi } from '../services/fetchProvinciasApi';


const useProvinciasLocalidades = () => {
  const [provincias, setProvincias] = useState<Provincia[]>([]);
  const [localidades, setLocalidades] = useState<Localidad[]>([]);
  const [selectedProvincia, setSelectedProvincia] = useState<Provincia | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchProvincias = async () => {
      try {
        const provincias = await fetchProvinciasApi();
        // Order provincias by name
        provincias.sort((a, b) => a.nombre.localeCompare(b.nombre));
        // Remove diacratics marks and convert to uppercase
        provincias.forEach((provincia) => {
          provincia.nombre = provincia.nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase();
        });
        setProvincias(provincias);
      } catch (error) {
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchProvincias();
  }, []);

  useEffect(() => {
    const fetchLocalidades = async () => {
      if (selectedProvincia === null) {
        setLocalidades([]);
        return;
      }
      try {
        setLoading(true);
        const localidades = await fetchLocalidadesApi(selectedProvincia.id);
        // Order localidades by name
        localidades.sort((a, b) => a.nombre.localeCompare(b.nombre));
        // Remove diacratics marks and convert to uppercase
        localidades.forEach((localidad) => {
          localidad.nombre = localidad.nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase();
        });
        setLocalidades(localidades);
      } catch (error) {
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchLocalidades();
  }, [selectedProvincia]); // This effect runs when selectedProvincia changes

  return { provincias, localidades, loading, error, setSelectedProvincia };
};

export default useProvinciasLocalidades;