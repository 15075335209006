import { apiFetch } from "../../../../../../services/api";

export async function deleteInviteApi(
    id: number
): Promise<void>{
    try {
        // Get token from sessionStorage
        const token = sessionStorage.getItem('token');

        // Fetch from api and pass token for authentication
        const response = await apiFetch(
            `/ecommerce/invites/${id}`,
            {
                method: "DELETE",
                headers: {
                    "Authorization": `Token ${token}`
            }
        });

        if (!response.ok){
            throw new Error(response.statusText);
        }
        return;
    } catch (error) {
        return;
    }
}