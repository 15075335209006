import { apiFetch } from "../../../../../../services/api";
import { Invite } from "../../models/Invite";

export async function fetchInvites(
    eventId: number
): Promise<Invite[]>{
    try {
        // Get token from sessionStorage
        const token = sessionStorage.getItem('token');

        // Fetch from api and pass token for authentication
        const response = await apiFetch(
            `/ecommerce/invites?event_id=${eventId}`,
            {
                headers: {
                    "Authorization": `Token ${token}`
            }
        });

        if (!response.ok){
            throw new Error(response.statusText);
        }

        const data = await response.json();

        // Parse data into list of tickets

        return data as Invite[];
        
    } catch (error) {
        return null;
    }
}