import { Box, Button, Text } from "@chakra-ui/react";
import Card from "components/card/Card";
import { useNavigate } from "react-router-dom";

export default function Status(props: { status: string }) {

    const navigate = useNavigate();
    return (
        <Box>
            <Card flexDirection='column' w='100%' p='20px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
                {/* Title */}
                <Text fontSize='3xl' fontWeight='bold' textAlign='center' mb='20px'>
                    {
                        props.status === 'success'
                        ? 'Se vinculó tu cuenta de Mercado Pago correctamente!'
                        : '¡Ups! Hubo un error al vincular tu cuenta de Mercado Pago'
                    }
                </Text>
                {/* Description */}
                <Text fontSize='lg' textAlign='center' mb='20px'>
                    {
                        props.status === 'success'
                        ? 'Ya puedes comenzar a recibir pagos con Mercado Pago'
                        : 'Por favor, intenta nuevamente'
                    }
                </Text>
                <Button
                    variant='brand'
                    fontWeight='500'
                    w='100%'
                    h='50'
                    mb='24px'
                    onClick={() => {
                        if (props.status === 'success') {
                            navigate('/');
                        } else {
                            sessionStorage.clear();
                            navigate('/auth/sign-in');
                        }
                    }}
                    >
                    Volver a inicio
                </Button>
            </Card>
        </Box>
    );
}