import { useEffect, useState } from "react";
import { Venue } from "../models/Venue";
import { fetchVenues } from "../services/fetchVenues";

export default function VenuesHook() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [venues, setVenues] = useState<Venue[]>([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        fetchVenues().then((data: Venue[]) => {
            setVenues(data);
        }).catch((error: string) => {
            setError(error);
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);

    return { venues, isLoading, error };
}