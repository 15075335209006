import { useState } from 'react';
import { apiFetch } from 'services/api';

const useNoUserFormSubmit = (eventId?: number) => {
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(null);

    const submitForm = async (data: any) => {
        setLoading(true);
        try {
            const token = sessionStorage.getItem('token');
            const response = await apiFetch(
                `/ecommerce/no-user-invite/${eventId}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${token}`,
                    },
                    body: JSON.stringify(data),
                }
            );
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            setLoading(false);
            setError(null);
            setSubmitted(true);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    return { submitForm, loading, error, submitted };
}

export default useNoUserFormSubmit;