import { apiFetch } from "services/api";
import PromotionItem from "../../models/PromotionItem";

export async function postPromotionItemApi(promotionId: number, promotionItems: PromotionItem[]): Promise<number> {
    try {
        // Get token from sessionStorage
        const token = sessionStorage.getItem('token');

        // Fetch from api and pass token for authentication
        const response = await apiFetch(
            `/ecommerce/promotions/${promotionId}/items`,
            {
                method: 'POST',
                headers: {
                    "Authorization": `Token ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(promotionItems)
            }
        );

        if (!response.ok) {
            throw new Error(response.statusText);
        }

        const data = await response.json();

        return data.id as number;
    } catch (error) {
        throw error;
    }
}