import {
    Box,
    Flex,
    Text,
    Spinner,

} from '@chakra-ui/react';
import EventForm from '../components/EventForm';
import CreateEventHook from './hooks/CreateEventHook';

export default function CreateEventPage() {

    const {
        isLoading,
        predios,
        categorias
    } = CreateEventHook();

    if (!predios || !categorias) {
        return (
            <Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
                <Flex justifyContent='center' alignItems='center' height='100vh'>
                    <Text>Error al cargar el evento</Text>
                </Flex>
            </Box>
        );
    }

    return (
        <Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
            {
                (
                    isLoading
                    ? <Flex justifyContent='center' alignItems='center' height='100vh'>
                        <Spinner />
                    </Flex>
                    : <EventForm event={null} predios={predios} categorias={categorias}/>
                )
            }
        </Box>
    );
}