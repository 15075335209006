import { useState, useEffect } from 'react';
import Predio from '../../models/Predio';
import Categoria from '../../models/Categoria';
import { fetchPredios } from 'views/admin/events/services/fetchPredios';
import { fetchCategorias } from 'views/admin/events/services/fetchCategorias';

export default function UpdateEventHook() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [predios, setPredios] = useState<Predio[]>([]);
    const [categorias, setCategorias] = useState<Categoria[]>([]);

    useEffect(() => {
        setIsLoading(true);
        fetchPredios().then((data: Predio[]) => {
            setPredios(data);
        });
        fetchCategorias().then((data: Categoria[]) => {
            setCategorias(data);
        });
        setIsLoading(false);
    }, []);

    return { isLoading, predios, categorias };
}