import {
    Box,
    Text,
    Button
} from '@chakra-ui/react';
import Card from 'components/card/Card';

export default function MercadoPagoOauthTokenStatusPage() {

    // Get query params
    const urlParams = new URLSearchParams(window.location.search);
    const oauthUrl = urlParams.get('oauthUrl');

    return (
        <Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
            <Card flexDirection='column' w='100%' p='20px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
                {/* Title */}
                <Text fontSize='3xl' fontWeight='bold' textAlign='center' mb='20px'>
                    Vincular cuenta de MercadoPago
                </Text>
                {/* Description */}
                <Text fontSize='lg' textAlign='center' mb='20px'>
                    Para poder crear un evento es necesario vincular tu cuenta de MercadoPago. También, vas a poder vincular varias cuentas en la sección "Pagos".
                </Text>
                {/* Important description */}
                <Text fontSize='lg' textAlign='center' mb='20px' color='red.500'>
                    Importante: No se va a cobrar ningún monto de tu cuenta de MercadoPago.
                </Text>
                {/* Button to redirect to mercadopago page */}
                <Button
                    variant='brand'
                    fontWeight='500'
                    w='100%'
                    h='50'
                    mb='24px'
                    onClick={() => {
                        if (oauthUrl) {
                            window.location.href = oauthUrl;
                        }
                    }}>
                    Vincular
                </Button>
            </Card>
        </Box>
    );
}