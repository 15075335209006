import { apiFetch } from '../../../../services/api';
import { GenderCount } from '../models/GenderCount';

export async function fetchGenderCounts(): Promise<GenderCount[]>{
    // Get token from sessionStorage
    const token = sessionStorage.getItem('token');

    // Fetch from api and pass token for authentication
    const response = await apiFetch(
        '/analytics/demo/gender',
        {
            headers: {
                "Authorization": `Token ${token}`
        }
    });

    const data = await response.json();

    // Parse data to GenderCount[]
    return data;
}