import { apiFetch } from "../../../../services/api";
import Categoria from "../pages/models/Categoria";

export async function fetchCategorias(): Promise<Categoria[]>{
    // Get token from sessionStorage
    const token = sessionStorage.getItem('token');

    // Fetch from api and pass token for authentication
    const response = await apiFetch(
        '/eventos/categorias',
        {
            headers: {
                "Authorization": `Token ${token}`
            }
        });

    const data = await response.json();

    return data as Categoria[];
}