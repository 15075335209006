import { useState, useEffect } from 'react';
import { SalePeriod } from '../models/SalePeriod';
import { GenderCount } from '../models/GenderCount';
import { AgeCount } from '../models/AgeCount';
import { fetchSalesByPeriod } from '../services/salesByPeriod';
import { fetchGenderCounts} from '../services/genderCounts';
import { fetchAgeCounts} from '../services/ageCounts';

export default function SalesReports(){
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [dailySales, setDailySales] = useState<SalePeriod[]>([]);
    const [monthlySales, setMonthlySales] = useState<SalePeriod[]>([]);
    // const [yearlySales, setYearlySales] = useState<SalePeriod[]>([]);
    const [dailyKpi, setDailyKpi] = useState([]);
    const [monthlyKpi, setMonthlyKpi] = useState([]);
    // const [yearlyKpi, setYearlyKpi] = useState([]);
    const [genderCounts, setGenderCounts] = useState([]);
    const [ageCounts, setAgeCounts] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        fetchSalesByPeriod('day').then((data: SalePeriod[]) => {
            setDailySales(data);
            setDailyKpi(getDailyKpi(data));
        });
        fetchSalesByPeriod('month').then((data: SalePeriod[]) => {
            setMonthlySales(data)
            setMonthlyKpi(getMonthlyKpi(data));

        });
        // fetchSalesByPeriod('year').then((data: SalePeriod[]) => {
        //     setYearlySales(data)
        //     setYearlyKpi(getYearlyKpi(data));
        // });
        fetchGenderCounts().then((data: GenderCount[]) => setGenderCounts(data));
        fetchAgeCounts().then((data: AgeCount[]) => setAgeCounts(data));
        setIsLoading(false);
    }, []);
    
    return { isLoading, dailySales, monthlySales, dailyKpi, monthlyKpi, genderCounts, ageCounts};
}


function getDailyKpi(
    dailySales: SalePeriod[],
) {
    // Get today date
    const today = new Date();
    // Get yesterday date
    const yesterday = new Date(today);

    yesterday.setDate(yesterday.getDate() - 1);
    // Get SalePeriod from today
    const todaySale: SalePeriod | undefined = dailySales.find((Sale: SalePeriod) => Sale.periodo.toLocaleDateString() === today.toLocaleDateString());
    // Get SalePeriod from yesterday
    const yesterdaySale: SalePeriod | undefined = dailySales.find((Sale: SalePeriod) => Sale.periodo.toLocaleDateString() === yesterday.toLocaleDateString());

    // Set data to kpi
    return [
        todaySale ? parseFloat(todaySale.total.toFixed(2)) : 0.0,
        yesterdaySale ? parseFloat(yesterdaySale.total.toFixed(2)) : 0.0,
    ];
}

function getMonthlyKpi(monthlySales: SalePeriod[]) {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
  
    const thisMonthSale: SalePeriod | undefined = monthlySales.find(
      (sale: SalePeriod) =>
        new Date(sale.periodo).getMonth() === currentMonth &&
        new Date(sale.periodo).getFullYear() === currentYear
    );
  
    const lastMonth = new Date(today);
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    const lastMonthYear = lastMonth.getFullYear();
    const lastMonthMonth = lastMonth.getMonth();
  
    const lastMonthSale: SalePeriod | undefined = monthlySales.find(
      (sale: SalePeriod) =>
        new Date(sale.periodo).getMonth() === lastMonthMonth &&
        new Date(sale.periodo).getFullYear() === lastMonthYear
    );
  
    return [
      thisMonthSale ? thisMonthSale.total : 0.0,
      lastMonthSale ? lastMonthSale.total : 0.0,
    ];
  }

// function getYearlyKpi(
//     yearlySales: SalePeriod[],
// ) {
//     // Get today date
//     const currentYear = new Date().getFullYear();
    
//     // Get yesterday date
//     const lastYear = new Date(today);
//     lastYear.setFullYear(lastYear.getFullYear() - 1);
//     // Get total sales of this year
//     const thisYearSales = salesData
//         .filter((sale: SalePeriod) => new Date(sale.periodo).getFullYear() === currentYear)
//         .reduce((total: number, sale: SalePeriod) => total + sale.total, 0);

//     console.log(thisYearSales);

//     // Set data to kpi
//     return [
//         0, 0
//     ];
// }