import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import PromotionUpdateForm from "./components/PromotionUpdateForm";
import UpdatePromotionHook from "./hooks/UpdatePromotionHook";

export default function UpdatePromotionPage() {
    const { promotionId, eventId } = useParams();
    const parsedPromotionId = parseInt(promotionId);

    const {
        promotion,
        isLoading,
        error
    } = UpdatePromotionHook(parsedPromotionId);

    return (
        <Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
            {
                (
                    isLoading
                    ? <Flex justifyContent='center' alignItems='center' height='100vh'>
                        <Spinner />
                    </Flex>
                    : error
                        ? <Flex justifyContent='center' alignItems='center' height='100vh'>
                            <Text>Ha ocurrido un error. Intente de nuevo más tarde.</Text>
                        </Flex>
                        : <PromotionUpdateForm promotion={promotion} eventId={eventId} />
                )
            }
        </Box>
    );
}

