import { useNavigate } from "react-router-dom";
import Ticket from "../../models/Ticket";
import Card from "components/card/Card";
import TicketItem from "./TicketItem";
import { Flex, Text, Button, useColorModeValue, Icon, Divider } from "@chakra-ui/react";
import { MdAdd } from "react-icons/md";

interface TicketsListProps {
    eventId: number;
    tickets: Ticket[];
    [x: string]: any;
}

export default function TicketsList(props: TicketsListProps) {
    const { eventId, tickets } = props;

    const navigate = useNavigate();

    const textColor = useColorModeValue('secondaryGray.900', 'white');
	const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
	const bgHover = useColorModeValue({ bg: 'secondaryGray.400' }, { bg: 'whiteAlpha.50' });
	const bgFocus = useColorModeValue({ bg: 'secondaryGray.300' }, { bg: 'whiteAlpha.100' });

    return (
        <Card alignItems='center' flexDirection='column' w='100%' mb="1rem">
            <Flex
                align={{ sm: 'flex-start', lg: 'center' }}
                justify='space-between'
                w='100%'
                px='22px'
                py='18px'>
                <Text color={textColor} fontSize='xl' fontWeight='600'>
                    Lista de entradas
                </Text>

                <Button
                    alignItems='center'
                    justifyContent='center'
                    bg={bgButton}
                    _hover={bgHover}
                    _focus={bgFocus}
                    _active={bgFocus}
                    w='37px'
                    h='37px'
                    lineHeight='100%'
                    borderRadius='10px'
                    mr='10px'
                    onClick={() => {
                        navigate(`/admin/eventos/${eventId}/entradas/crear`);
                    }}
                    >
                    <Icon as={MdAdd} />
                </Button>
            </Flex>
            <Divider />
            {tickets.map((ticket: Ticket, index: number) => (
                <TicketItem key={ticket.id} ticket={ticket}/>
            ))}
        </Card>
    );
}