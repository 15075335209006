import React, { useState } from 'react';
import { Field, FieldProps } from 'formik';
import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import MiniCalendar from 'components/calendar/MiniCalendar';
import { format } from 'date-fns';

interface DateFieldProps {
    fieldName: string;
    label: string;
}

const DateField: React.FC<DateFieldProps> = ({ fieldName, label }) => {
    const [calendarVisible, setCalendarVisible] = useState(false);

    return (
        <Field name={fieldName}>
            {({ field, form }: FieldProps) => (
                <FormControl isRequired={ true } mb="1rem">
                    <FormLabel htmlFor={fieldName}>{label}</FormLabel>
                    <Input
                        readOnly
                        value={field.value ? field.value : ''}
                        onClick={() => setCalendarVisible(true)}
                    />
                    {calendarVisible && (
                        <MiniCalendar
                            selectRange={false}
                            initialDate={field.value}
                            onValueChange={(date: Date) => {
                                const formattedDate = format(date, 'yyyy-MM-dd');
                                form.setFieldValue(field.name, formattedDate);
                                setCalendarVisible(false);
                            }}
                        />
                    )}
                </FormControl>
            )}
        </Field>
    );
};

export default DateField;