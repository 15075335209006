import { apiFetch } from "../../../../../../services/api";
import NoUserInvite from "../../models/NoUserInvite";


export async function fetchNoUserInvites(
    eventId: number
): Promise<NoUserInvite[]>{
    // Get token from sessionStorage
    const token = sessionStorage.getItem('token');

    // Fetch from api and pass token for authentication
    const response = await apiFetch(
        `/ecommerce/no-user-invite/${eventId}`,
        {
            headers: {
                "Authorization": `Token ${token}`
        }
    });

    const data = (await response.json());

    return data as NoUserInvite[];
}