import { apiFetch } from "services/api";


export async function postProductApi(formData: FormData): Promise<void> {
    try {
        // Get token from sessionStorage
        const token = sessionStorage.getItem('token');

        // Fetch from api and pass token for authentication
        const response = await apiFetch(
            '/products/',
            {
                method: 'POST',
                headers: {  
                    "Authorization": `Token ${token}`,
                },
                body: formData
            }
        );

        if (!response.ok) {
            throw new Error(response.statusText);
        }


        return;
    } catch (error) {
        throw error;
    }
}