// Chakra imports
import { Button, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { MdConfirmationNumber, MdEdit } from 'react-icons/md';
// Custom components
import Card from 'components/card/Card';
import Ticket from '../../models/Ticket';
import { useNavigate } from 'react-router-dom';

export default function TicketItem(props: {
	ticket: Ticket
}) {
	const { ticket } = props;

	const navigate = useNavigate();
	
	// Chakra Color Mode
	const textColor = useColorModeValue('brands.900', 'white');
	const bgItem = useColorModeValue(
		{ bg: 'white', boxShadow: '0px 40px 58px -20px rgba(112, 144, 176, 0.12)' },
		{ bg: 'navy.700', boxShadow: 'unset' }
	);

    // const textColor = useColorModeValue('secondaryGray.900', 'white');
    const iconColor = useColorModeValue('brand.500', 'white');
	const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
	const bgHover = useColorModeValue({ bg: 'secondaryGray.400' }, { bg: 'whiteAlpha.50' });
	const bgFocus = useColorModeValue({ bg: 'secondaryGray.300' }, { bg: 'whiteAlpha.100' });

    let startsAt = '';
    if (ticket.startsAt) {
        startsAt = ticket.startsAt.split('T')[0] + ' ' + ticket.startsAt.split('T')[1].substring(0, 5);
    } else {
        startsAt = 'No definida';
    }

    let endsAt = '';
    if (ticket.endsAt) {
        endsAt = ticket.endsAt.split('T')[0] + ' ' + ticket.endsAt.split('T')[1].substring(0, 5);
    } else {
        endsAt = 'No definida';
    }

	return (
		<Card _hover={bgItem} bg='transparent' boxShadow='unset' px='24px' py='21px' transition='0.2s linear'>
			<Flex direction={{ base: 'column' }} justify='center'>
				<Flex position='relative' align='center'>
                    <Icon as={MdConfirmationNumber} color={textColor} boxSize='20px' me='16px' />
                    <Flex
						direction='column'
						w={{ base: '70%', md: '100%' }}
						me={{ base: '4px', md: '32px', xl: '10px', '3xl': '32px' }}>
						<Text
							color={textColor}
							fontSize={{
								base: 'md'
							}}
							mb='5px'
							fontWeight='bold'
							me='14px'>
							{ticket.tipo}
						</Text>
						<Text
							color='secondaryGray.600'
							fontSize={{
								base: 'sm'
							}}
							fontWeight='400'
							me='14px'>
							Precio: ${ticket.precio}
						</Text>
						<Text
							color='secondaryGray.600'
							fontSize={{
								base: 'sm'
							}}
							fontWeight='400'
							me='14px'>
							Cantidad disponible: {ticket.cantidad}
						</Text>
						<Text
							color='secondaryGray.600'
							fontSize={{
								base: 'sm'
							}}
							fontWeight='400'
							me='14px'>
							Privacidad: {ticket.privacidad}
						</Text>
						{/* <Text
							color='secondaryGray.600'
							fontSize={{
								base: 'sm'
							}}
							fontWeight='400'
							me='14px'>
							Fecha de inicio: {startsAt}
						</Text>
						<Text
							color='secondaryGray.600'
							fontSize={{
								base: 'sm'
							}}
							fontWeight='400'
							me='14px'>
							Fecha de finalización: {endsAt}
						</Text> */}
					</Flex>
					<Flex me={{ base: '4px', md: '32px', xl: '10px', '3xl': '32px' }} align='center'>
						<Button
							alignItems='center'
							justifyContent='center'
							bg={bgButton}
							_hover={bgHover}
							_focus={bgFocus}
							_active={bgFocus}
							w='37px'
							h='37px'
							lineHeight='100%'
							borderRadius='10px'
							onClick={() => {
								navigate(`/admin/eventos/${ticket.evento}/entradas/${ticket.id}/editar`);
							}}
							>
							<Icon as={MdEdit} color={iconColor} w='24px' h='24px' />
						</Button>
					</Flex>
					{/* <Text ms='auto' fontWeight='700' fontSize='sm' color={textColorDate}>
						{date}
					</Text> */}
				</Flex>
			</Flex>
		</Card>
	);
}
