import  {
    Divider,
    Flex,
    Text,
    Button,
    Icon,
    useColorModeValue,
    useToast,
    useMediaQuery
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { MdEdit, MdShare } from 'react-icons/md';
import Card from 'components/card/Card';
import Event from '../../models/Event';

export interface EventInfoProps {
    event: Event;
    [x: string]: any;
}

export default function EventInfo(props: EventInfoProps) {

    const { event } = props;
    
    const toast = useToast();
    const navigate = useNavigate();

    const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const iconColor = useColorModeValue('brand.500', 'white');
	const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
	const bgHover = useColorModeValue({ bg: 'secondaryGray.400' }, { bg: 'whiteAlpha.50' });
	const bgFocus = useColorModeValue({ bg: 'secondaryGray.300' }, { bg: 'whiteAlpha.100' });

    const eventLink = `https://www.tickazo.com/events?eventId=${event.id}`;

    const eventStartDatetimeUtc = new Date(event.eventStartDatetimeUtc);
    // convert to YYYY-MM-DD and HH:MM format
    const startDate = eventStartDatetimeUtc.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
    const startTime = eventStartDatetimeUtc.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' });

    const eventEndDatetimeUtc = new Date(event.eventEndDatetimeUtc);
    // convert to YYYY-MM-DD and HH:MM format
    const endDate = eventEndDatetimeUtc.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
    const endTime = eventEndDatetimeUtc.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' });



    return (
        <Card alignItems='center' flexDirection='column' w='100%' mb="1rem">
            <Flex align='center' w='100%' px='15px' py='10px'>
                <Text me='auto' color={textColor} fontSize='xl' fontWeight='700' lineHeight='100%'>
                    {event.nombre}
                </Text>
                <Button
                    alignItems='center'
                    justifyContent='center'
                    bg={bgButton}
                    _hover={bgHover}
                    _focus={bgFocus}
                    _active={bgFocus}
                    w='37px'
                    h='37px'
                    lineHeight='100%'
                    borderRadius='10px'
                    mr='10px'
                    onClick={() => {
                        navigator.clipboard.writeText(eventLink);
                        toast({
                            title: 'Enlace copiado',
                            description: 'El enlace del evento ha sido copiado al portapapeles',
                            status: 'success',
                            duration: 3000,
                            isClosable: true,
                            position: 'top-right'
                        });
                    }}
                    >
                    <Icon as={MdShare} />
                </Button>
                <Button
                    alignItems='center'
                    justifyContent='center'
                    bg={bgButton}
                    _hover={bgHover}
                    _focus={bgFocus}
                    _active={bgFocus}
                    w='37px'
                    h='37px'
                    lineHeight='100%'
                    borderRadius='10px'
                    onClick={() => {
                        navigate(`/admin/eventos/${event.id}/edit`);
                    }}
                    >
                    <Icon as={MdEdit} color={iconColor} w='24px' h='24px' />
                </Button>
            </Flex>
            <Divider />
            <Flex align='center' w='100%' px='15px' py='10px'>
                <Text me='auto' color={textColor} fontSize='l' lineHeight='100%'>
                    Categoria: {event.categoria.nombre}
                </Text>
            </Flex>
            <Flex align='center' w='100%' px='15px' py='10px'>
                <Text me='auto' color={textColor} fontSize='l' lineHeight='100%'>
                    Predio: {event.predio.nombre}
                </Text>
            </Flex>
            <Flex align='center' w='100%' px='15px' py='10px'>
                <Text me='auto' color={textColor} fontSize='l' lineHeight='100%'>
                    Edad minima: {event.edad}
                </Text>
            </Flex>
            <Flex align='center' w='100%' px='15px' py='10px'>
                <Text me='auto' color={textColor} fontSize='l' lineHeight='100%'>
                    Fecha de comienzo: {startDate}
                </Text>
            </Flex>
            <Flex align='center' w='100%' px='15px' py='10px'>
                <Text me='auto' color={textColor} fontSize='l' lineHeight='100%'>
                    Hora de comienzo: {startTime}
                </Text>
            </Flex>
            <Flex align='center' w='100%' px='15px' py='10px'>
                <Text me='auto' color={textColor} fontSize='l' lineHeight='100%'>
                    Fecha de finalización: {endDate}
                </Text>
            </Flex>
            <Flex align='center' w='100%' px='15px' py='10px'>
                <Text me='auto' color={textColor} fontSize='l' lineHeight='100%'>
                    Hora de finalización: {endTime}
                </Text>
            </Flex>
            <Flex align='center' w='100%' px='15px' py='10px'>
                <Text me='auto' color={textColor} fontSize='l' lineHeight='100%'>
                    Habilitado?: {event.habilitado ? 'Si' : 'No'}
                </Text>
            </Flex>
            <Flex align='center' w='100%' px='15px' py='10px'>
                <Text me='auto' color={textColor} fontSize='l' lineHeight='100%'>
                    Descripción: {event.desc}
                </Text>
            </Flex>
        </Card>
    );
}