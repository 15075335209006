import React from 'react';

// Chakra imports
import {
    Box,
    Flex,
    Spinner
} from '@chakra-ui/react';

// Custom components
import EventsTable from './components/EventsTable';
import EventsHook from './hooks/EventsHook';


export default function Events() {
    const {
        isLoading,
        events,
    } = EventsHook();

    return (
        <Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
            {
                (
                    isLoading
                    ? <Flex justifyContent='center' alignItems='center' height='100vh'>
                        <Spinner />
                    </Flex>
                    : <EventsTable tableData={events} />
                )
            }
        </Box>
    );
}