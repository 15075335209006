// Chakra components
import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    Icon,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Text,
    useColorModeValue,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
// Custom components
import Card from 'components/card/Card';
import { MdEdit } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import useFormSubmit from '../hooks/useFormSubmit';


interface UpdateItemProps {
    title: string;
    field: string;
    type: string;
    value: string | number;
    maxLength?: number;
    options?: string[];
}

export default function UpdateItem(props: UpdateItemProps) {

    const { title, field, type, value, options } = props;

    const { eventId, ticketId } = useParams();

    const toast = useToast();

    const navigate = useNavigate();
    
    // Chakra modal
    const { isOpen, onOpen, onClose } = useDisclosure();

    // Chakra Color Mode
    const textColor = useColorModeValue('brands.900', 'white');
    const bgItem = useColorModeValue(
        { bg: 'white', boxShadow: '0px 40px 58px -20px rgba(112, 144, 176, 0.12)' },
        { bg: 'navy.700', boxShadow: 'unset' }
    );
    const iconColor = useColorModeValue('brand.500', 'white');
    const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const bgHover = useColorModeValue({ bg: 'secondaryGray.400' }, { bg: 'whiteAlpha.50' });
    const bgFocus = useColorModeValue({ bg: 'secondaryGray.300' }, { bg: 'whiteAlpha.100' });
 
    const { submitForm, loading, submitted, error } = useFormSubmit(parseInt(ticketId));

    const hanleOnSubmit = async (data: any) => {
        const result: string = await submitForm(data);
        if (result === 'success') {
            onClose();
            navigate(`/admin/eventos/${eventId}`);
        }
    }

    useEffect(() => {
        if (error && !loading) {
            toast({
                title: 'Error al actualizar el ticket',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top-right'
            });
        } else if (!error && !loading && submitted) {
            toast({
                title: 'Ticket actualiado!',
                status: 'success',
                duration: 3000,
                isClosable: true,
                position: 'top-right'
            });
        }
    }, [submitted, error, loading, toast]);

    return (
        <Card _hover={bgItem} bg='transparent' boxShadow='unset' px='24px' py='21px' transition='0.2s linear'>
            <Flex direction={{ base: 'column' }} justify='center'>
                <Flex position='relative' align='center'>
                    <Flex
                        direction='column'
                        w={{ base: '70%', md: '100%' }}
                        me={{ base: '4px', md: '32px', xl: '10px', '3xl': '32px' }}>
                        <Text
                            color={textColor}
                            fontSize={{
                                base: 'md'
                            }}
                            mb='5px'
                            fontWeight='bold'
                            me='14px'>
                            {title}
                        </Text>
                        <Text
                            color='secondaryGray.600'
                            fontSize={{
                                base: 'sm'
                            }}
                            fontWeight='400'
                            me='14px'>
                            {value}
                        </Text>
                    </Flex>
                    <Flex me={{ base: '4px', md: '32px', xl: '10px', '3xl': '32px' }} align='center'>
                        <Button
                            alignItems='center'
                            justifyContent='center'
                            bg={bgButton}
                            _hover={bgHover}
                            _focus={bgFocus}
                            _active={bgFocus}
                            w='37px'
                            h='37px'
                            lineHeight='100%'
                            borderRadius='10px'
                            onClick={onOpen}
                            >
                            <Icon as={MdEdit} color={iconColor} w='24px' h='24px' />
                        </Button>
                    </Flex>
                    <Modal isOpen={isOpen} onClose={onClose}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Actualizar {title.toLowerCase()}</ModalHeader>
                            <ModalCloseButton />
                            <Formik
                                initialValues={{
                                    [field]: value,
                                    ...(field === 'cantidad' && { accion: '' })
                                } as  { [key: string]: any}}
                                onSubmit={hanleOnSubmit}
                                validate={(values: { [key: string]: any }) => {
                                    const errors: { [key: string]: string } = {};
                                    if (field === 'cantidad' && values.accion === 'REMOVER' && values[field] > value) {
                                        errors[field] = 'La cantidad no puede quedar en negativo';
                                    }
                                    if (type === 'number' && values[field] < 0) {
                                        errors[field] = 'El valor no puede ser negativo';
                                    }
                                    return errors;
                                }}
                            >
                                <Form>
                                    <ModalBody>
                                    {field === 'cantidad' && (
                                        <Field name="accion">
                                            {({ field }: any) => (
                                                <FormControl isRequired={true} mb="1rem">
                                                    <Select {...field} id='accion' placeholder='Seleccione una acción'>
                                                        <option value="AGREGAR">AGREGAR</option>
                                                        <option value="REMOVER">QUITAR</option>
                                                    </Select>
                                                </FormControl>
                                            )}
                                        </Field>
                                    )}
                                    <Field name={field}>
                                        {({ field, meta }: any) => (
                                            <FormControl isRequired={true} mb="1rem" isInvalid={meta.error && meta.touched}>
                                                {field.name === 'privacidad' || field.name === 'stock' ? (
                                                    <Select {...field} id={field.name}>
                                                        {options.map((option) => (
                                                            <option key={option} value={option}>{option}</option>
                                                        ))}
                                                    </Select>
                                                ) : (
                                                    <>
                                                        <Input {...field} id={field.name} type={type} {...(props.maxLength ? { maxLength: props.maxLength } : {})}/>
                                                        {props.maxLength && (
                                                            <FormHelperText>
                                                                {field.value?.length ?? 0}/{props.maxLength} caracteres
                                                            </FormHelperText>
                                                        )}
                                                    </>
                                                )}
                                                <FormErrorMessage>{meta.error}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    </ModalBody>

                                    <ModalFooter>
                                        <Button variant="ghost" onClick={onClose}>Cancelar</Button>
                                        <Button
                                            type='submit'
                                            isLoading={loading}
                                            variant="action"
                                            size='lg'
                                            fontSize='md'
                                            fontWeight='500'
                                            borderRadius='15px'
                                            mx={{ base: "auto", lg: "unset" }}
                                            me='auto'
                                            mb={{ base: "20px", md: "auto" }}
                                        >
                                            Confirmar
                                        </Button>
                                    </ModalFooter>
                                </Form>
                            </Formik>
                        </ModalContent>
                    </Modal>
                </Flex>
            </Flex>
        </Card>
    )
}
