import {
    useToast,
    FormControl,
    FormLabel,
    Input,
    Select,
    Button,
    NumberInput,
    NumberInputField,
    FormHelperText,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, FieldProps } from 'formik';
import Card from "components/card/Card";
import { useEffect } from "react";
import { Venue } from "../models/Venue";
import useFormSubmit from "../hooks/useFormSubmit";
import ImageUploader from "views/admin/events/pages/components/ImageUploader";
import useProvinciasLocalidades from "../hooks/useProvinciasLocalidades";
import { Provincia } from "../models/Provincia";
import { Localidad } from "../models/Localidad";

interface VenueFormProps {
    venue?: Venue;
}

interface VenueFormValues {
    [key: string]: any;
    nombre: string;
    calle: string;
    altura: number;
    localidad: string;
    provincia: string;
    latitude: number;
    longitude: number;
    img: string;
}

export default function VenueForm(props: VenueFormProps) {
    const { venue } = props;

    const toast = useToast();

    const navigate = useNavigate();

    const { provincias, localidades, loading: provinceLoading, error: provinceError, setSelectedProvincia } = useProvinciasLocalidades();

    const { submitForm, loading: formLoading, submitted, error: formError } = useFormSubmit();

    const getChangedValues = (values: VenueFormValues, venue: any): Partial<VenueFormValues> => {
        let changedValues: Partial<VenueFormValues> = {};

        if (venue) {
            for (const key in values) {
                if (values[key] !== (venue as any)[key]) {
                    changedValues[key] = values[key];
                }
            }
        } else {
            changedValues = values;
        }
        return changedValues;
    };

    const getFormData = (changedValues: Partial<VenueFormValues>): FormData => {

        let formData = new FormData();
        for (const key in changedValues) {

            if (changedValues[key] instanceof File) {
                formData.append(key, changedValues[key], changedValues[key].name);
            } else {
                formData.append(key, changedValues[key]);
            }
        }
        return formData;
    };

    

    const handleOnSubmit = async (data: VenueFormValues) => {
        const changedValues = getChangedValues(data, venue);
        const formData = getFormData(changedValues);
        await submitForm(formData, venue?.id);
    }

    useEffect(() => {
        if (formError && !formLoading) {
            toast({
                title: (venue) ? 'Error al actualizar el predio' : 'Error al crear el predio',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top-right'
            });
        } else if (!formError && !formLoading && submitted) {
            toast({
                title: (venue) ? 'Predio actualizado' : 'Predio creado!',
                status: 'success',
                duration: 3000,
                isClosable: true,
                position: 'top-right'
            });
            navigate('/admin/predios/');
        }
    }, [submitted, formError, formLoading, toast, navigate, venue]);

    const initialValues: VenueFormValues = {
        nombre: venue?.nombre || '',
        calle: venue?.calle || '',
        altura: venue?.altura || 0,
        localidad: venue?.localidad || '',
        provincia: venue?.provincia || '',
        latitude: venue?.latitude || 0,
        longitude: venue?.longitude || 0,
        img: venue?.img || '',
    };
    

    return (
        <Card>
            <Formik
                initialValues={initialValues}
                onSubmit={handleOnSubmit}
            >
                {(props) => (
                    <Form>
                        <Field name='nombre'>
                            {({ field }: FieldProps) => (
                                <FormControl isRequired={true} mb="1rem">
                                    <FormLabel htmlFor='nombre'>Nombre</FormLabel>
                                    <Input {...field} id='nombre' maxLength={32} />
                                    <FormHelperText>
                                        {field.value?.length ?? 0}/32 caracteres
                                    </FormHelperText>
                                </FormControl>
                            )}
                        </Field>
                        <Field name='calle'>
                            {({ field }: FieldProps) => (
                                <FormControl isRequired={true} mb="1rem">
                                    <FormLabel htmlFor='calle'>Calle</FormLabel>
                                    <Input {...field} id='calle' maxLength={32} />
                                    <FormHelperText>
                                        {field.value?.length}/32 caracteres
                                    </FormHelperText>
                                </FormControl>
                            )}
                        </Field>
                        <Field name='altura'>
                            {({ field, form }: FieldProps) => (
                                <FormControl isRequired={true} mb="1rem">
                                    <FormLabel htmlFor='altura'>Altura</FormLabel>
                                    <NumberInput id='altura' min={0} max={99999999} value={field.value} onChange={(valueString, valueNumber) => form.setFieldValue(field.name, valueNumber)}>
                                        <NumberInputField />
                                    </NumberInput>
                                </FormControl>
                            )}
                        </Field>
                        <Field name='provincia'>
                            {({ field, form }: FieldProps) => (
                                <FormControl isRequired={true} mb="1rem">
                                    <FormLabel htmlFor='provincia'>Provincia</FormLabel>
                                    <Select
                                        placeholder='Seleccione una provincia'
                                        {...field}
                                        id='provincia'
                                        onChange={(e) => {
                                            const newValue = e.target.value;
                                            // Search newValue in provincias and set the selected provincia
                                            const selectedProvincia = provincias.find(provincia => provincia.nombre === newValue);
                                            setSelectedProvincia(selectedProvincia);
                                            // set localidades to empty array
                                            form.setFieldValue('localidad', '');
                                            form.setFieldValue(field.name, newValue);
                                        }}
                                    >
                                        {
                                            provincias.map((provincia: Provincia) => (
                                                <option key={provincia.id} value={provincia.nombre}>{provincia.nombre}</option>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            )}
                        </Field>
                        <Field name='localidad'>
                            {({ field, form }: FieldProps) => (
                                <FormControl isRequired={true} mb="1rem">
                                    <FormLabel htmlFor='localidad'>Localidad</FormLabel>
                                    <Select placeholder='Seleccione una localidad' {...field} id='localidad'>
                                        {
                                            localidades.map((localidad: Localidad) => (
                                                <option key={localidad.id} value={localidad.nombre}>{localidad.nombre}</option>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            )}
                        </Field>
                        <Field name='latitude'>
                            {({ field, form }: FieldProps) => (
                                <FormControl isRequired={true} mb="1rem">
                                    <FormLabel htmlFor='latitude'>Latitud</FormLabel>
                                    <Input {...field} id='latitude' maxLength={12} type="number" step="any" min="-90" max="90" onChange={(e) => form.setFieldValue(field.name, e.target.value)} />
                                </FormControl>
                            )}
                        </Field>
                        <Field name='longitude'>
                            {({ field, form }: FieldProps) => (
                                <FormControl isRequired={true} mb="1rem">
                                    <FormLabel htmlFor='longitude'>Longitud</FormLabel>
                                    <Input {...field} id='longitude' maxLength={12} type="number" step="any" min="-180" max="180" onChange={(e) => form.setFieldValue(field.name, e.target.value)} />
                                </FormControl>
                            )}
                        </Field>
                        <Field name='img'>
                            {({ field, form }: FieldProps) => (
                                <FormControl isRequired={true} mb="1rem">
                                    <FormLabel htmlFor='img'>Imagen del predio</FormLabel>
                                    <ImageUploader fieldName="img" orientation="horizontal" />
                                </FormControl>
                            )}
                        </Field>
                        <Button
                            type='submit'
                            isLoading={formLoading}
                            colorScheme='brand'
                            size='lg'
                            fontSize='md'
                            fontWeight='500'
                            borderRadius='15px'
                            mx={{ base: "auto", lg: "unset" }}
                            me='auto'
                            mb={{ base: "20px", md: "auto" }}>
                            {venue === null ? 'Crear' : 'Actualizar'}
                        </Button>
                    </Form>
                )}
            </Formik>
        </Card>
    )


}