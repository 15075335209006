import { useState } from "react";
import {
    Box,
    Button,
    Flex,
    Icon,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useMediaQuery,
    Badge,
	useToast
} from '@chakra-ui/react';
import {
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable
} from '@tanstack/react-table';
import { MdCancel, MdCheckCircle } from 'react-icons/md';
import Card from 'components/card/Card';
import { useNavigate } from "react-router-dom";
import { MercadoPagoToken } from "../models/MercadoPagoToken";
import { apiFetch } from "services/api";


interface MercadoPagoTokensTableProps {
    mercadoPagoTokens: MercadoPagoToken[];
}

const columnHelper = createColumnHelper<MercadoPagoToken>();

export default function CompanyUsersTable (props: MercadoPagoTokensTableProps) {
    const navigate = useNavigate();
	const { mercadoPagoTokens } = props;
	const [ sorting, setSorting ] = useState<SortingState>([]);
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
    const [isMobile] = useMediaQuery("(max-width: 480px)");
	const toast = useToast();
    
    const columns = [
		columnHelper.accessor('description', {
			id: 'description',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Nombre
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Text color={textColor} fontSize='sm' fontWeight='700'>
						{info.getValue()}
					</Text>
				</Flex>
			)
		}),
        columnHelper.accessor('amount_sold', {
			id: 'amount_sold',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Identificador
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Text color={textColor} fontSize='sm' fontWeight='700'>
						{info.getValue()}
					</Text>
				</Flex>
			)
		}),
        columnHelper.accessor('is_active', {
			id: 'is_active',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					ESTADO
				</Text>
			),
			cell: (info) => (
			<Flex align='center'>
				<Icon
					w='24px'
					h='24px'
					me='5px'
					color={
                        info.getValue()
                        ? 'green.500'
                        : 'red.500'
					}
					as={
						info.getValue()
                        ? MdCheckCircle
						: MdCancel
					}
				/>
				<Text color={textColor} fontSize='sm' fontWeight='700'>
					{
                        info.getValue()
                        ? 'Habilitado'
                        : 'Deshabilitado'
                    }
				</Text>
			</Flex> 
			)
		}),
		
		// Show a button to get more details of the event
        columnHelper.accessor('id', {
            id: 'changeStatus',
            header: () => (
                <Text
                justifyContent='space-between'
                align='center'
                fontSize={{ sm: '10px', lg: '12px' }}
                color='gray.400'>
                
                </Text>
            ),
            cell: (info) => {

				const mercadoPagoToken = info.row.original;

				// Count the number of active tokens
				const activeTokensCount = mercadoPagoTokens.filter(token => token.is_active).length;

            
                return (
					<Button
						variant='brand'
						fontSize='sm'
                        disabled={activeTokensCount === 1 && mercadoPagoToken.is_active}
						onClick={() => changeMercadoPagoTokenStatus(
							mercadoPagoToken.id,
							mercadoPagoToken.is_active ? 'disable' : 'enable'
						)}
					>
						{
                            mercadoPagoToken.is_active
                            ? 'Deshabilitar'
                            : 'Habilitar'
                        }
					</Button>
                );
            }
        }),
    ];


	// Function to disable or enable a token
	const changeMercadoPagoTokenStatus = async (id: number, action: string) => {
		// Get user token
		const token = sessionStorage.getItem('token');
		// Put request to change the status of the token
		const response = await apiFetch(
			`/ecommerce/payment/mercadopago-tokens/${id}`,
			{
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Token ${token}`
				},
				body: JSON.stringify({
					action: action
				})
			}
		);
		// Show toast if the request was successful
		if (response.ok){
			// reload the page
			window.location.reload();
		} else {
			toast({
				title: 'Error',
				description: 'Hubo un error al intentar actualizar el estado del token de Mercado Pago',
				status: 'error',
				duration: 3000,
				isClosable: true
			});
		}
	}


    const table = useReactTable({
		data: mercadoPagoTokens,
		columns,
		state: {
			sorting
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		debugTable: true
	});
    return (
        <Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
				<Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
					Mis cuentas de Mercado Pago
				</Text>
                <Button
                    variant='brand'
                    fontSize='sm'
                    onClick={() => navigate('/admin/finanzas/mercadopago/crear')
                    }
                >
                    {isMobile ? "+" : "+ Conectar otra cuenta de Mercado Pago"}
                </Button>
			</Flex>
			<Box>
				<Table variant='simple' color='gray.500' mb='24px' mt="12px">
					<Thead>
						{table.getHeaderGroups().map((headerGroup) => (
							<Tr key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									return (
										<Th
											key={header.id}
											colSpan={header.colSpan}
											pe='10px'
											borderColor={borderColor}
											cursor='pointer'
											onClick={header.column.getToggleSortingHandler()}>
											<Flex
												justifyContent='space-between'
												align='center'
												fontSize={{ sm: '10px', lg: '12px' }}
												color='gray.400'>
												{flexRender(header.column.columnDef.header, header.getContext())}{{
													asc: '',
													desc: '',
												}[header.column.getIsSorted() as string] ?? null}
											</Flex>
										</Th>
									);
								})}
							</Tr>
						))}
					</Thead>
					<Tbody>
						{table.getRowModel().rows.slice(0, 11).map((row) => {
							return (
								<Tr key={row.id}>
									{row.getVisibleCells().map((cell) => {
										return (
											<Td
												key={cell.id}
												fontSize={{ sm: '14px' }}
												minW={{ sm: '150px', md: '200px', lg: 'auto' }}
												borderColor='transparent'>
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</Td>
										);
									})}
								</Tr>
							);
						})}
					</Tbody>
				</Table>
                <Flex justifyContent='center' align='center'>
                    <Text color={textColor} fontSize='14px' fontWeight='400'>
                        El identificador se reinicia a 0 todos los meses.
                    </Text>
                </Flex>

			</Box>
		</Card>
    );
}