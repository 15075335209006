import { useState } from "react";
import { putProduct } from "../services/putProduct";

const useFormSubmit = (id: number) => {
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(null);

    const submitForm = async (data: any) => {
        setError(null);
        setLoading(true);
        try {
            await putProduct(id, data);
            setSubmitted(true);
            setLoading(false);
            setError(null);
            return 'success';
        } catch (error) {
            setError(error);
            setLoading(false);
            return 'error';
        }
    }

    return { submitForm, loading, submitted, error };
}

export default useFormSubmit;