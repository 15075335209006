import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import Card from "components/card/Card";
import { useParams } from "react-router-dom";
import UpdateItem from "./components/UpdateItem";
import ProductHook from "./hooks/ProductHook";

export default function UpdateProductPage() {
    const { productId } = useParams();

    const { product, isLoading, error } = ProductHook(parseInt(productId));
    
    return (
        <Flex justifyContent='center' alignItems='center'>
            <Box pt={{ base: '180px', md: '80px', xl: '80px' }} w={{ base: '100%', md: '40%' }}>
                {
                    (
                        isLoading
                        ? <Flex justifyContent='center' alignItems='center' height='100vh'>
                            <Spinner />
                        </Flex>
                        : error
                        ? <Flex justifyContent='center' alignItems='center' height='100vh'>
                            <Text>Error al cargar el producto</Text>
                        </Flex>
                            : <Card>
                                <Flex align='center' justifyContent='center' w='100%' px='15px' py='10px'>
                                    <Text fontSize='xl' fontWeight='700' lineHeight='100%'>
                                        Actualizar producto
                                    </Text>
                                </Flex>
                                <UpdateItem title='Nombre' field='name' type='text' value={product.name} />
                                {/* <UpdateItem title='Categoría' field='category' type='text' value={product.category} /> */}
                                <UpdateItem title='Edad minima' field='minimum_age' type='text' value={product.minimumAge} />
                                <UpdateItem title='Precio' field='price' type='number' value={product.price} />
                                <UpdateItem title='Cantidad' field='quantity' type='number' value={product.quantity} />
                                <UpdateItem title='Descripción' field='description' type='text' value={product.description} />
                            </Card>
                    )
                }
            </Box>
        </Flex>
    )
}