import { apiFetch } from "services/api";


export async function postMercadoPagoOauthTokenApi(code: string, state: string): Promise<Response> {
    try {
        // Get token from sessionStorage
        const token = sessionStorage.getItem('token');
        
        const data = {code, state};

        // Fetch from api and pass token for authentication
        const response = await apiFetch(
            '/ecommerce/payment/mercadopago-tokens',
            {
                method: 'POST',
                headers: {
                    "Authorization": `Token ${token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            }
        );

        return response;
    } catch (error) {
        throw error;
    }
}