import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import PromotionForm from "./components/PromotionForm";
import CreatePromotionHook from "./hooks/CreatePromotionHook";

export default function CreatePromotionPage() {
    const { eventId } = useParams();
    const parsedEventId = parseInt(eventId);

    const {
        tickets,
        products,
        isLoading,
        error
    } = CreatePromotionHook(parsedEventId);

    return (
        <Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
            {
                (
                    isLoading
                    ? <Flex justifyContent='center' alignItems='center' height='100vh'>
                        <Spinner />
                    </Flex>
                    : error
                        ? <Flex justifyContent='center' alignItems='center' height='100vh'>
                            <Text>Ha ocurrido un error. Intente de nuevo más tarde.</Text>
                        </Flex>
                        : <PromotionForm promotion={null} eventId={parsedEventId} tickets={tickets} products={products} />
                )
            }
        </Box>
    );
}

