import { useState } from 'react';
import { postCompanyUserApi } from '../services/postCompanyUserApi';
import { postUserApi } from '../services/postUserApi';
import { postUserMenuItemApi } from '../services/postUserMenuItemApi';

const useFormSubmit = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    const submitUser = async (formData: FormData) => {
        const data = await postUserApi(formData);

        if (!data.id) {
            throw new Error('Usuario no creado');
        }

        // get user id from response body
        return data.id;
    }

    const submitCompanyUser = async (formData: FormData, userId: string) => {
        // add user id to form data
        formData.append('user', userId);
        const data = await postCompanyUserApi(formData);
        if (!data.id) {
            throw new Error('Usuario de empresa no creado');
        }
    }

    const submitForm = async (userData: FormData, companyUserData: FormData, menuItems: string[]) => {
        // submit user form if ok then submit company user form
        try {
            const userId = await submitUser(userData);
            await submitCompanyUser(companyUserData, userId);
            // For loop to add menu items to user
            for (let i = 0; i < menuItems.length; i++) {
                await postUserMenuItemApi(userId, menuItems[i]);
            }
            setLoading(false);
            setSubmitted(true);
            setError(null);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    return { submitForm, loading, error, submitted };
}

export default useFormSubmit;
