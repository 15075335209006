import { apiFetch } from "services/api";


export async function postInviteApi(data: any): Promise<void> {
    try {
        // Get token from sessionStorage
        const token = sessionStorage.getItem('token');

        // Fetch from api and pass token for authentication
        const response = await apiFetch(
            '/ecommerce/invites',
            {
                method: 'POST',
                headers: {  
                    "Authorization": `Token ${token}`,
                    "Content-Type": 'application/json'
                },
                body: JSON.stringify(data)
            }
        );

        if (!response.ok) {
            throw new Error(response.statusText);
        }


        return;
    } catch (error) {
        throw error;
    }
}