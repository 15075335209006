import { useState } from 'react';
import PromotionItem from '../../../../models/PromotionItem';
import { postPromotionApi } from '../../../services/postPromotionApi';
import { postPromotionItemApi } from '../../../services/postPromotionItemApi';

const useFormSubmit = (type: string, id?: number) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const submitForm = async (formData: FormData) => {
        setLoading(true);
        try {
            // post promotion
            const promotionData = {
                name: formData.get('name') as string,
                category: formData.get('category') as string,
                discount_percentage: Number(formData.get('discount_percentage')) / 100,
                max_order_quantity: Number(formData.get('max_order_quantity')),
                start_datetime_utc: formData.get('start_datetime_utc'),
                end_datetime_utc: formData.get('end_datetime_utc'),
                event: formData.get('event') as string,
            };
            const promotionId = await postPromotionApi(promotionData);

            // get value from form data
            const promotionItems: PromotionItem[] = [];
            for (let key of formData.keys()) {
                if (key.startsWith('products[') || key.startsWith('tickets[')) {
                    const promotionItem = formData.get(key);
                    if (promotionItem) {
                        const parsedItem = JSON.parse(promotionItem as string);
                        parsedItem.promotion = promotionId;
                        if (parsedItem.category === 'PRODUCT') {
                            delete parsedItem.ticket;
                        }
                        if (parsedItem.category === 'TICKET') {
                            delete parsedItem.product;
                        }
                        promotionItems.push(parsedItem);
                    }
                }
            }
            await postPromotionItemApi(promotionId, promotionItems);


            setLoading(false);
            setError(null);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    return { submitForm, loading, error };
}

export default useFormSubmit;