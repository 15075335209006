import React, { useState } from 'react';
import { Flex, Button, Spinner, Text, useToast } from '@chakra-ui/react';
import { Field, useField } from 'formik';

interface ImageUploaderProps {
    fieldName: string;
    orientation: 'horizontal' | 'vertical' | 'square';
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ fieldName, orientation }) => {
    
    const toast = useToast();
    
    const [field, meta, helpers] = useField(fieldName);
    const [loading, setLoading] = useState(false);

    const pickImageFromGallery = () => {
        return new Promise<File | null>((resolve, reject) => {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = 'image/*';
    
            input.onchange = (event) => {
                const file = (event.target as HTMLInputElement).files?.[0] || null;
                resolve(file);
            };
    
            input.onerror = (error) => {
                reject(error);
            };

            input.oncancel = () => {
                resolve(null);
            }
    
            input.click();
        });
    };

    const handleImageUpload = async () => {
        setLoading(true);
        const image = await pickImageFromGallery(); // You need to implement this function

        if (image) {
            const fileSize = image.size / (1024 * 1024); // size in MB

            if (fileSize > 0.5) {
                toast({
                    title: 'Imagen demasiado grande',
                    description: 'La imagen debe pesar menos de 500 KB',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right'
                });
            } else {
                // Get now timestamp
                // const timestamp = new Date().getTime();
                // // Extract the file extension from the original image name
                // const fileExtension = image.name.split('.').pop();
                // // Construct the new image name with the timestamp, _predio, and the original file extension
                // const imageName = `${timestamp}_predio.${fileExtension}`;

                helpers.setValue(image);
            }
        }

        setLoading(false);
    };

    let actionText = field.value ? 'Cambiar imagen' : 'Subir imagen';

    // function to decide aspectRatio based on orientation
    const aspectRatio = () => {
        switch (orientation) {
            case 'horizontal':
                return '16/9';
            case 'vertical':
                return '9/16';
            case 'square':
                return '1/1';
            default:
                return '16/9';
        }
    }

    const getImgDimensions = () => {
        switch (orientation) {
            case 'horizontal':
                return { height: '240px', width: '426px' };
            case 'vertical':
                return { height: '426px', width: '240px' };
            case 'square':
                return { height: '240px', width: '240px' };
            default:
                return { height: '426px', width: '240px' };
        }
    }

    return (
        <Field name={field.name}>
            {() => (
                <Flex direction="column" align="center">
                    {field.value && (
                        <Flex
                            style={{
                                height: getImgDimensions().height,
                                width: getImgDimensions().width,
                            }}
                        >
                            <img
                                src={typeof field.value === 'string' ? field.value : URL.createObjectURL(field.value)} 
                                alt="Poster"
                                style={{ objectFit: 'fill', aspectRatio: aspectRatio() }}
                            />
                        </Flex>
                    )}
                    <Button variant="action" onClick={handleImageUpload} disabled={loading} marginY="1rem">
                        {
                            loading
                            ? <Spinner />
                            : actionText}
                    </Button>
                    <Text variant="body2" color="textSecondary">
                        Tamaño maximo de la imagen: 500 KB
                    </Text>
                </Flex>
            )}
        </Field>
    );
};

export default ImageUploader;