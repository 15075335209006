// Chakra imports
import {
    Box,
    Button,
    Flex,
    Icon,
    Text,
    useColorModeValue
} from '@chakra-ui/react';
import Card from 'components/card/Card';
// Custom components
import BarChart from 'components/charts/BarChart';
import React from 'react';
import { MdBarChart } from 'react-icons/md';

export interface AgeBarPlotProps {
    xSerie: any[];
    ySeries: any[];
    [x: string]: any;
}

export default function AgeBarPlot(props: AgeBarPlotProps) {
	var { xSerie, ySeries, ...rest } = props;

	// Chakra Color Mode
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const iconColor = useColorModeValue('brand.500', 'white');
	const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
	const bgHover = useColorModeValue({ bg: 'secondaryGray.400' }, { bg: 'whiteAlpha.50' });
	const bgFocus = useColorModeValue({ bg: 'secondaryGray.300' }, { bg: 'whiteAlpha.100' });

    const chartOptions = getChartOptions(xSerie);

	return (
		<Card alignItems='center' flexDirection='column' w='100%' {...rest}>
			<Flex align='center' w='100%' px='15px' py='10px'>
				<Text me='auto' color={textColor} fontSize='xl' fontWeight='700' lineHeight='100%'>
					Distribución de edades
				</Text>
				<Button
					alignItems='center'
					justifyContent='center'
					bg={bgButton}
					_hover={bgHover}
					_focus={bgFocus}
					_active={bgFocus}
					w='37px'
					h='37px'
					lineHeight='100%'
					borderRadius='10px'
					{...rest}>
					<Icon as={MdBarChart} color={iconColor} w='24px' h='24px' />
				</Button>
			</Flex>

			<Box h='240px' mt='auto' w='100%'>
                {
                    (xSerie.length === 0)
                    ? <Flex justifyContent='center' alignItems='center' h='100%'>
                        <Text color={textColor} fontSize='xl' fontWeight='700' lineHeight='100%'>
                            No hay datos para mostrar.
                        </Text>
                    </Flex>
                    : <BarChart h='100%' w='100%' chartData={ySeries} chartOptions={chartOptions} />
                }
			</Box>
		</Card>
	);
}

function getChartOptions(xSerie: any[]) {
    return {
        chart: {
            stacked: true,
            toolbar: {
                show: false
            }
        },
        tooltip: {
            style: {
                fontSize: '12px',
                fontFamily: undefined as string | undefined
            },
            onDatasetHover: {
                style: {
                    fontSize: '12px',
                    fontFamily: undefined as string | undefined
                }
            },
            theme: 'dark'
        },
        xaxis: {
            categories: xSerie,
            show: false,
            labels: {
                show: true,
                style: {
                    colors: '#A3AED0',
                    fontSize: '14px',
                    fontWeight: '500'
                }
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            }
        },
        yaxis: {
            show: false,
            color: 'black',
            labels: {
                show: false,
                style: {
                    colors: '#A3AED0',
                    fontSize: '14px',
                    fontWeight: '500'
                }
            }
        },
    
        grid: {
            borderColor: 'rgba(163, 174, 208, 0.3)',
            show: true,
            yaxis: {
                lines: {
                    show: false,
                    opacity: 0.5
                }
            },
            row: {
                opacity: 0.5
            },
            xaxis: {
                lines: {
                    show: false
                }
            }
        },
        fill: {
            type: 'solid',
            colors: [ '#5E37FF', '#6AD2FF', '#E1E9F8' ]
        },
        legend: {
            show: false
        },
        colors: [ '#5E37FF', '#6AD2FF', '#E1E9F8' ],
        dataLabels: {
            enabled: false
        },
        plotOptions: {
            bar: {
                borderRadius: 10,
                columnWidth: '20px'
            }
        }
    };
}
