import { useState } from 'react';
import { submitVenueApi } from '../services/submitVenueApi';

const useFormSubmit = () => {
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(null);

    const submitForm = async (data: FormData, id?: number) => {
        setError(null);
        setLoading(true);
        try {
            await submitVenueApi(data, id);

            setSubmitted(true);
            setLoading(false);
            setError(null);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    return { submitForm, loading, submitted, error };
}

export default useFormSubmit;