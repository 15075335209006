import { apiFetch } from "services/api";
import Category from "../../models/Category";


export async function fetchCategorias(): Promise<Category[]>{
    // Get token from sessionStorage
    const token = sessionStorage.getItem('token');

    // Fetch from api and pass token for authentication
    const response = await apiFetch(
        '/products/categories',
        {
            headers: {
                "Authorization": `Token ${token}`
            }
        });

    const data = await response.json();

    return data as Category[];
}