import { useState, useEffect } from 'react';
import Category from '../../../models/Category';
import { fetchCategorias } from '../../services/fetchCategorias';

export default function CreateProductHook() {
    const [categories, setCategorias] = useState<Category[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        fetchCategorias().then((data: Category[]) => {
            setCategorias(data);
        }).catch((error) => {
            setError(error);
        });
        setIsLoading(false);
    }, []);

    return { categories, isLoading, error };
}