import { useEffect, useState } from "react";
import { postMercadoPagoOauthTokenApi } from "../services/postMercadoPagoOauthTokenApi";

export default function MercadoPagoOauthTokenHook(code: string, state: string) {
    const [isLoading, setIsLoading] = useState(true);
    const [status, setStatus] = useState('');
    
    useEffect(() => {
        setIsLoading(true);
        // post code and state to backend
        postMercadoPagoOauthTokenApi(code, state).then((data) => {
            if (data.status === 200) {
                setStatus('success');
                sessionStorage.setItem('isMercadoPagoTokenValid', data.status === 200 ? 'true' : 'false');
            } else {
                setStatus('error');
            }
        }).catch(() => {
            setStatus('error');
        }).finally(() => {
            setIsLoading(false);
        });
    }, [code, state]);
    return { isLoading, status };
}