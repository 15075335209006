import { apiFetch } from "services/api";
import { CompanyUser } from "../models/CompanyUser";

export async function fetchCompanyUsers(): Promise<CompanyUser[]> {
    const token = sessionStorage.getItem('token');

    const response = await apiFetch(
        '/auth/empresas/staff',
        {
            headers: {
                "Authorization": `Token ${token}`
        }
    });

    const data = await response.json();

    return data as CompanyUser[];
}