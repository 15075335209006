import { useEffect, useState } from "react";
import { MercadoPagoToken } from "../models/MercadoPagoToken";
import { fetchMercadoPagoTokens } from "../services/fetchMercadoPagoTokens";

export default function MercadoPagoTokensHook() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [mercadoPagoTokens, setMercadoPagoTokens] = useState<any[]>([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        fetchMercadoPagoTokens().then((data: MercadoPagoToken[]) => {
            setMercadoPagoTokens(data);
        }).catch((error: string) => {
            setError(error);
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);
    
    return { mercadoPagoTokens, isLoading, error };
}