import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Text,
  useColorModeValue,
  Spinner,
  Box
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

// Custom hooks
import useAuthHook from "./hooks/AuthHook";



function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const { isLoading, error, isAuthenticated, isMercadoPagoTokenValid, login, mercadoPagoOauthUrl } = useAuthHook();
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    await login(username, password);
  }

  useEffect(() => {
    if (isAuthenticated) {
      if (isMercadoPagoTokenValid) {
        navigate('/')
      } else {
        navigate(`/admin/payment/mercado-pago/status?oauthUrl=${encodeURIComponent(mercadoPagoOauthUrl)}`);
      }
    }
  }, [isAuthenticated, isMercadoPagoTokenValid, mercadoPagoOauthUrl, navigate]);

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        {/* <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Tickazo |  Administracion
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Enter your email and password to sign in!
          </Text>
        </Box> */}
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          <form onSubmit={handleLogin}>
            <FormControl>
              <FormLabel
                htmlFor='username'
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Celular<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup>
                <InputLeftAddon
                  children='+54'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  h='48px'
                />
                <Input
                  id='username'
                  isRequired={true}
                  variant='auth'
                  fontSize='sm'
                  ms={{ base: "0px", md: "0px" }}
                  type='number'
                  mb='24px'
                  fontWeight='500'
                  size='lg'
                  onChange={(e) => setUsername(e.target.value)}
                  autoComplete='username'
                />
              </InputGroup>
              <FormLabel
                htmlFor='password'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Contraseña<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  id='password'
                  isRequired={true}
                  fontSize='sm'
                  placeholder='Min. 8 caracteres'
                  mb='24px'
                  size='lg'
                  type={show ? "text" : "password"}
                  variant='auth'
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete='current-password'
                />
                <InputRightElement display='flex' alignItems='center' mt='4px'>
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              {/* <Flex justifyContent='space-between' align='center' mb='24px'>
                <NavLink to='/auth/forgot-password'>
                  <Text
                    color={textColorBrand}
                    fontSize='sm'
                    fontWeight='500'>
                    Olvidaste tu contraseña?
                  </Text>
                </NavLink>
              </Flex> */}
              {isLoading
                ? <Box display='flex' justifyContent='center'>
                    <Spinner />
                  </Box>
                : (
                  <Button
                    type='submit'
                    fontSize='sm'
                    variant='brand'
                    fontWeight='500'
                    w='100%'
                    h='50'
                    mb='24px'>
                    Iniciar Sesión
                  </Button>
                )
              }
              {error && <p style={{ color: 'red' }}>{error}</p>}
            </FormControl>
          </form>
          {/* <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
            <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              Not registered yet?
              <NavLink to='/auth/sign-up'>
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  fontWeight='500'>
                  Create an Account
                </Text>
              </NavLink>
            </Text>
          </Flex> */}
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
