import { useState, useEffect } from 'react';
import { EventSummary } from '../models/EventSummary';
import { fetchEvents } from '../services/fetchEvents';

export default function EventsHook() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [events, setEvents] = useState<EventSummary[]>([]);

    useEffect(() => {
        setIsLoading(true);
        fetchEvents().then((data: EventSummary[]) => {
            setEvents(data);
        });
        setIsLoading(false);
    }, []);

    return { isLoading, events };
}