import { useParams } from 'react-router-dom';
import {
    Box,
    Flex,
    Text,
    Spinner,

} from '@chakra-ui/react';
import EventForm from '../components/EventForm';
import EventHook from '../details/hooks/EventHook';
import UpdateEventHook from './hooks/UpdateEventHook';

export default function UpdateEventPage() {

    const { id } = useParams();
    var {
        isLoading: isEventHookLoading,
        event
    } = EventHook(Number(id));

    var {
        isLoading: isUpdateEventHookLoading,
        predios,
        categorias
    } = UpdateEventHook();

    const isLoading = isEventHookLoading || isUpdateEventHookLoading;
    const hasError = !event || !predios || !categorias;

    if (!isLoading && hasError) {
        return (
            <Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
                <Flex justifyContent='center' alignItems='center' height='100vh'>
                    <Text>Error al cargar el evento</Text>
                </Flex>
            </Box>
        );
    }

    return (
        <Box pt={{ base: '180px', md: '80px', xl: '80px' }} >
            {
                (
                    isLoading
                    ? <Flex justifyContent='center' alignItems='center' height='100vh'>
                        <Spinner />
                    </Flex>
                    : <EventForm event={event} predios={predios} categorias={categorias}/>
                )
            }
        </Box>
    );
}