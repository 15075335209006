import { apiFetch } from "services/api";

export async function putPromotionApi(promotionData: FormData, id: number): Promise<void> {
    try {
        // Get token from sessionStorage
        const token = sessionStorage.getItem('token');

        // Fetch from api and pass token for authentication
        const response = await apiFetch(
            `/ecommerce/promotions/${id}`,
            {
                method: 'PUT',
                headers: {
                    "Authorization": `Token ${token}`,
                    // "Content-Type": "application/json",
                },
                body: promotionData
            }
        );

        if (!response.ok) {
            throw new Error(response.statusText);
        }
    } catch (error) {
        throw error;
    }
}