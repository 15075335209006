import { apiFetch } from "../../../../../../services/api";
import Ticket from "../../models/Ticket";

export async function fetchTickets(
    eventId: number
): Promise<Ticket[]>{
    try {
        // Get token from sessionStorage
        const token = sessionStorage.getItem('token');

        // Fetch from api and pass token for authentication
        const response = await apiFetch(
            `/entradas?evento_id=${eventId}`,
            {
                headers: {
                    "Authorization": `Token ${token}`
            }
        });

        if (!response.ok){
            throw new Error(response.statusText);
        }

        var data = await response.json();

        // Parse data into list of tickets
        const tickets: Ticket[] = data.map((ticket: any) => {
            return {
                id: ticket.id,
                tipo: ticket.tipo,
                description: ticket.description,
                cantidad: ticket.cantidad,
                stock: ticket.stock === "true" ? true : false,
                precio: ticket.precio,
                privacidad: ticket.privacidad,
                limite: ticket.limite,
                startsAt: ticket.starts_at,
                endsAt: ticket.ends_at,
                evento: ticket.evento,
            }
        });

        return tickets as Ticket[];
        
    } catch (error) {
        return null;
    }
}