import React from 'react';
import { useNavigate } from 'react-router-dom';

interface AuthProps {
  children?: React.ReactNode;
}

const AuthWrapper: React.FC<AuthProps> = ({ children }) => {
  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!token) {
      navigate('/auth/sign-in');
    }
  }, [navigate, token]);

  if (!token) {
    return null;
  }

  // Render the wrapped component if a token is found
  return <>{children}</>;
};

export default AuthWrapper;
