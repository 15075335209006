import { useState, useEffect } from 'react';
import Promotion from 'views/admin/events/pages/models/Promotion';
import { fetchPromotion } from '../../../services/fetchPromotion';

export default function UpdatePromotionHook(eventId: number) {
    const [promotion, setPromotion] = useState<Promotion>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setError(null);
            try {
                const response = await fetchPromotion(eventId);
                setPromotion(response);
            } catch (err) {
                setError(err as Error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [eventId]);

    return { promotion, isLoading, error };
}