import { useEffect, useState } from "react";
import { CompanyUser } from "../models/CompanyUser";
import { fetchCompanyUsers } from "../services/fetchCompanyUsers";

export default function UsersHook() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [users, setUsers] = useState<CompanyUser[]>([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        fetchCompanyUsers().then((data: CompanyUser[]) => {
            setUsers(data);
        }).catch((error: string) => {
            setError(error);
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);

    return { users, isLoading, error, };
}