import { Box } from "@chakra-ui/react";
import VenueForm from "../../components/VenueForm";

export default function CreateVenuePage() {

    return (
        <Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
            <VenueForm venue={null}/>
        </Box>
    )
}