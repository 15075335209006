import { apiFetch } from '../../../../services/api';
import { SalePeriod } from '../models/SalePeriod';

export async function fetchSalesByPeriod(
    period: string,
): Promise<SalePeriod[]>{
    // Get token from sessionStorage
    const token = sessionStorage.getItem('token');

    // Fetch from api and pass token for authentication
    const response = await apiFetch(
        `/dashboard/sales?periodo=${period}`,
        { 
            headers: {
                "Authorization": `Token ${token}`
            }
        }
    );
    var data = await response.json();
    // Make test data

    data = data.map((item: any) => {
        const [year, month, day] = item.periodo.split('-');
        const salePeriod: SalePeriod = {
            periodo: new Date(Number(year), Number(month) - 1, Number(day)),
            total: item.total
        };
        return salePeriod;
    });
    return data;
}