import { useEffect } from "react";
import { Formik, Form, Field, FieldProps,} from "formik";
import {
    FormControl,
    FormLabel,
    Input,
    Button,
    NumberInput,
    NumberInputField,
    useToast,
    Select,
} from "@chakra-ui/react";
import Card from 'components/card/Card';
import { useNavigate } from "react-router-dom";
import Promotion from "../../../../models/Promotion";
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { format } from "date-fns";
import useFormSubmit from "../hooks/userFormSubmit";

export interface UpdatePromotionFormProps {
    promotion: Promotion;
    eventId: string;
    [x: string]: any;
}

interface FormValues {
    [key: string]: any;
    event: string;
    name: string;
    discount_percentage: number;
    max_order_quantity: number;
    start_datetime_utc: Date | null;
    end_datetime_utc: Date | null;
    is_active: boolean;
}

export default function UpdatePromotionForm(props: UpdatePromotionFormProps) {

    const toast = useToast();

    const navigate = useNavigate();

    const { promotion, eventId } = props;

    const isUpdateMode = promotion !== null;

    const { submitForm, loading, error } = useFormSubmit(promotion.id);

    const initialValues: FormValues = {
        event: eventId,
        name: '',
        discount_percentage: 0,
        max_order_quantity: 0,
        start_datetime_utc: null,
        end_datetime_utc: null,
        is_active: false,
    };

    if (isUpdateMode) {
        initialValues.name = promotion.name;
        initialValues.discount_percentage = promotion.discount_percentage * 100;
        initialValues.max_order_quantity = promotion.max_order_quantity;
        initialValues.start_datetime_utc = promotion.start_datetime_utc;
        initialValues.end_datetime_utc = promotion.end_datetime_utc;
        initialValues.is_active = promotion.is_active;
    }

    useEffect(() => {
        if (error) {
          toast({
            title: isUpdateMode ? 'Error al actualizar la promoción' : 'Error al crear la promoción',
            description: 'Por favor, intenta nuevamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      }, [error, isUpdateMode, toast]);
    
      const getChangedValues = (values: FormValues, event: any): Partial<FormValues> => {
        let changedValues: Partial<FormValues> = {};
        if (isUpdateMode) {
            for (const key in values) {
                if (values[key] !== (event as any)[key]) {
                    changedValues[key] = values[key];
                }
            }
            changedValues = { ...changedValues, event: eventId };
            if (changedValues.discount_percentage) {
                changedValues.discount_percentage = changedValues.discount_percentage / 100;
            }
        } else {
            changedValues = { ...values };
        }
        return changedValues;
    };
    
    const getFormData = (values: Partial<FormValues>): FormData => {
    
        let formData = new FormData();
        for (const key in values) {
            if (key === 'start_datetime_utc' || key === 'end_datetime_utc') {
                if (values[key] !== null) {
                    formData.append(key, values[key].toISOString());
                }
            } else if (key === 'tickets' || key === 'products') {
                (values[key] as any[]).forEach((item, index) => {
                    formData.append(`${key}[${index}]`, JSON.stringify(item));
                });
            } else {
                formData.append(key, values[key]);
            }
        }
        return formData;
    };
    
    const onSubmit = async (values: FormValues) => {
        const changedValues = getChangedValues(values, promotion);
        const formData = getFormData(changedValues);
        await submitForm(formData);

        if (!error) {
            // Show toast and then navigate
            toast({
                title: isUpdateMode ? 'Promoción actualizada' : 'Promoción creada',
                description: isUpdateMode ? 'La promoción se actualizó correctamente.' : 'La promoción se creó correctamente.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            // Wait for toast to disappear and then navigate
            navigate(`/admin/eventos/${eventId}`);
                    
        }
    };

    return (
        <Card>
            <Formik
                initialValues = {initialValues}
                onSubmit = {onSubmit}
            >
                {(props) => (
                    <Form>
                        <Field name='name'>
                            {({ field, form }: FieldProps) => (
                                <FormControl isRequired={ true } mb="1rem">
                                    <FormLabel htmlFor='name'>Nombre</FormLabel>
                                    <Input {...field} id='name' />
                                </FormControl>
                            )}
                        </Field>
                        <Field name='max_order_quantity'>
                            {({ field, form}: FieldProps) => (
                                <FormControl isRequired={ true } mb="1rem">
                                    <FormLabel htmlFor='max_order_quantity'>Cantidad máxima por compra</FormLabel>
                                    <NumberInput id='max_order_quantity' min={1} value={field.value} onChange={(valueString, valueNumber) => form.setFieldValue(field.name, valueNumber)}>
                                        <NumberInputField />
                                    </NumberInput>
                                </FormControl>
                            )}
                        </Field>
                        <Field name='discount_percentage'>
                            {({ field, form}: FieldProps) => (
                                <FormControl isRequired={ true } mb="1rem">
                                    <FormLabel htmlFor='discount_percentage'>Porcentaje de descuento</FormLabel>
                                    <NumberInput id='discount_percentage' min={0} max={90} value={field.value} onChange={(valueString, valueNumber) => form.setFieldValue(field.name, valueNumber)}>
                                        <NumberInputField />
                                    </NumberInput>
                                </FormControl>
                            )}
                        </Field>
                        <Field name='start_datetime_utc'>
                            {({ field, form }: FieldProps) => (
                                <FormControl mb="1rem">
                                    <FormLabel htmlFor='start_datetime_utc'>Fecha y hora de inicio</FormLabel>
                                    <Datetime
                                        value={field.value}
                                        onChange={(date) => form.setFieldValue(field.name, date)}
                                        renderInput={(props, openCalendar) => (
                                            <Input
                                                {...props}
                                                onClick={openCalendar}
                                                readOnly
                                                value={props.value ? format(new Date(props.value), 'yyyy-MM-dd HH:mm:ss') : ''}
                                            />
                                        )}
                                        inputProps={{ id: 'start_datetime_utc', readOnly: true }}
                                    />
                                </FormControl>
                            )}
                        </Field>
                        <Field name='end_datetime_utc'>
                            {({ field, form }: FieldProps) => (
                                <FormControl mb="1rem">
                                    <FormLabel htmlFor='end_datetime_utc'>Fecha y hora de fin</FormLabel>
                                    <Datetime
                                        value={field.value}
                                        onChange={(date) => form.setFieldValue(field.name, date)}
                                        renderInput={(props, openCalendar) => (
                                            <Input
                                                {...props}
                                                onClick={openCalendar}
                                                readOnly
                                                value={props.value ? format(new Date(props.value), 'yyyy-MM-dd HH:mm:ss') : ''}
                                            />
                                        )}
                                        inputProps={{ id: 'end_datetime_utc', readOnly: true }}
                                    />
                                </FormControl>
                            )}
                        </Field>
                        <Field name='is_active'>
                            {({ field, form }: FieldProps) => (
                                <FormControl mb="1rem">
                                    <FormLabel htmlFor='is_active'>Activa</FormLabel>
                                    <Select
                                        {...field}
                                        id='is_active'
                                        placeholder='Selecciona una opción'
                                        onChange={(e) => form.setFieldValue(field.name, e.target.value)}
                                    >
                                        <option value='true'>Sí</option>
                                        <option value='false'>No</option>
                                    </Select>
                                </FormControl>
                            )}
                        </Field>
                        <Button
                            type='submit'
                            isLoading={loading}
                            colorScheme='brand'
                            size='lg'
                            fontSize='md'
                            fontWeight='500'
                            borderRadius='15px'
                            mx={{ base: "auto", lg: "unset" }}
                            me='auto'
                            mb={{ base: "20px", md: "auto" }}>
                            {isUpdateMode ? 'Actualizar promoción' : 'Crear promoción'}
                        </Button>

                    </Form>
                )}
            </Formik>
        </Card>
    );
}