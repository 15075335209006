import { useEffect } from "react";
import { Formik, Form, Field, FieldProps} from "formik";
import {
    FormControl,
    FormLabel,
    Input,
    Select,
    Button,
    NumberInput,
    NumberInputField,
    useToast,
    Textarea,
} from "@chakra-ui/react";
import Card from 'components/card/Card';
import { useNavigate } from "react-router-dom";
import Product from "../../models/Product";
import useFormSubmit from "../hooks/useFormSubmit";
import Category from "../../models/Category";

export interface ProductFormProps {
    product?: Product;
    eventId: number;
    categories: Category[];
    [x: string]: any;
}

interface FormValues {
    [key: string]: any;
    name: string;
    price: string | number;
    quantity: string | number;
    description: string;
    minimumAge: string | number;
    category: string | number;
}

export default function ProductForm(props: ProductFormProps) {

    const toast = useToast();

    const navigate = useNavigate();

    const { product, eventId, categories } = props;

    const isUpdateMode = product !== null;

    const { submitForm, loading, error } = useFormSubmit(isUpdateMode ? 'update' : 'create', isUpdateMode ? product.id : null);

    const initialValues: FormValues = {
        event: eventId,
        name: '',
        price: '',
        quantity: '',
        description: '',
        minimumAge: '',
        category: '',
    };

    if (isUpdateMode) {
        initialValues.name = product.name;
        initialValues.price = product.price;
        initialValues.quantity = product.quantity;
        initialValues.description = product.description;
        initialValues.minimumAge = product.minimumAge;
        initialValues.category = product.category.id;
    }

    useEffect(() => {
        if (error) {
          toast({
            title: isUpdateMode ? 'Error al actualizar el producto' : 'Error al crear el producto',
            description: 'Por favor, intenta nuevamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      }, [error, isUpdateMode, toast]);
    
      const getChangedValues = (values: FormValues, event: any): Partial<FormValues> => {
        let changedValues: Partial<FormValues> = {};
        if (isUpdateMode) {
            for (const key in values) {
                if (values[key] !== (event as any)[key]) {
                    changedValues[key] = values[key];
                }
            }
        } else {
            changedValues = { ...values };
        }
        return changedValues;
    };
    
    const getFormData = (changedValues: Partial<FormValues>): FormData => {
        const fieldMapping: { [key: string]: string } = {
            minimumAge: 'minimum_age',
        };
    
        let formData = new FormData();
        for (const key in changedValues) {
            let adjustedKey = fieldMapping[key] || key;
            if (changedValues[key] instanceof File) {
                formData.append(adjustedKey, changedValues[key], changedValues[key].name);
            } else {
                formData.append(adjustedKey, changedValues[key]);
            }
        }
        return formData;
    };
    
    const onSubmit = async (values: FormValues) => {
        const changedValues = getChangedValues(values, product);
        const formData = getFormData(changedValues);
        await submitForm(formData);

        if (!error) {
            // Show toast and then navigate
            toast({
                title: isUpdateMode ? 'Producto actualizado' : 'Producto creado',
                description: isUpdateMode ? 'El producto se actualizó correctamente.' : 'El producto se creó correctamente.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            // Wait for toast to disappear and then navigate
            navigate(`/admin/eventos/${eventId}`);
                    
        }
    };

    return (
        <Card>
            <Formik
                initialValues = {initialValues}
                onSubmit={onSubmit}
            >
                {(props) => (
                    <Form>
                        <Field name='category'>
                            {({ field, form }: FieldProps) => (
                                <FormControl isRequired={ true } mb="1rem">
                                    <FormLabel htmlFor='category'>Categoria</FormLabel>
                                    <Select placeholder='Seleccione un categoria' {...field} id='category'>
                                        {
                                            categories.map((category: Category) => (
                                                <option key={category.id} value={category.id}>{category.name}</option>
                                                ))
                                            }
                                    </Select>
                                </FormControl>
                            )}
                        </Field>
                        <Field name='name'>
                            {({ field, form }: FieldProps) => (
                                <FormControl isRequired={ true } mb="1rem">
                                    <FormLabel htmlFor='name'>Nombre</FormLabel>
                                    <Input {...field} id='name' />
                                </FormControl>
                            )}
                        </Field>
                        <Field name='quantity'>
                            {({ field, form}: FieldProps) => (
                                <FormControl isRequired={ true } mb="1rem">
                                    <FormLabel htmlFor='quantity'>Cantidad</FormLabel>
                                    <NumberInput id='quantity' min={1} value={field.value} onChange={(valueString, valueNumber) => form.setFieldValue(field.name, valueNumber)}>
                                        <NumberInputField />
                                    </NumberInput>
                                </FormControl>
                            )}
                        </Field>
                        <Field name='price'>
                            {({ field, form}: FieldProps) => (
                                <FormControl isRequired={ true } mb="1rem">
                                    <FormLabel htmlFor='price'>Precio</FormLabel>
                                    <NumberInput id='price' min={100} value={field.value} onChange={(valueString, valueNumber) => form.setFieldValue(field.name, valueNumber)}>
                                        <NumberInputField />
                                    </NumberInput>
                                </FormControl>
                            )}
                        </Field>
                        <Field name='minimumAge'>
                            {({ field, form }: FieldProps) => (
                                <FormControl isRequired={ true } mb="1rem">
                                    <FormLabel htmlFor='minimumAge'>Edad minima</FormLabel>
                                    <NumberInput id='minimumAge' min={0} max={99} value={field.value} onChange={(valueString, valueNumber) => form.setFieldValue(field.name, valueNumber)}>
                                        <NumberInputField />
                                    </NumberInput>
                                </FormControl>
                            )}
                        </Field>
                        <Field name='description'>
                            {({ field, form }: FieldProps) => (
                                <FormControl isRequired={ false } mb="1rem">
                                    <FormLabel htmlFor='description'>Descripción</FormLabel>
                                    <Textarea {...field} id='description' maxLength={128} />
                                </FormControl>
                            )}
                        </Field>
                        <Button
                            type='submit'
                            isLoading={loading}
                            colorScheme='brand'
                            size='lg'
                            fontSize='md'
                            fontWeight='500'
                            borderRadius='15px'
                            mx={{ base: "auto", lg: "unset" }}
                            me='auto'
                            mb={{ base: "20px", md: "auto" }}>
                            Crear
                        </Button>

                    </Form>
                )}
            </Formik>
        </Card>
    );
}