import { useState, useEffect, useCallback } from 'react';
import { fetchTickets } from "../../details/services/fetchTickets";
import Ticket from "../../models/Ticket";
import { postInviteApi } from '../services/postInviteApi';

export default function InviteHook(eventId: number) {
    const [isLoading, setIsLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(null);
    const [tickets, setTickets] = useState<Ticket[]>([]);

    const getTickets = useCallback(async () => {
        setIsLoading(true);
        fetchTickets(eventId).then((data: Ticket[]) => {
            setTickets(data);
            setIsLoading(false);
        });
    }, [eventId]);

    const submitForm = async (data: any) => {
        setIsLoading(true);
        postInviteApi(data)
            .then(() => {
                setSubmitted(true);
                setError(null);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }


    useEffect(() => {
        getTickets();
    }, [getTickets]);

    return { isLoading, tickets, submitForm, submitted, error};
}