import {
    Box,
    Flex,
    Spinner
} from '@chakra-ui/react';
import Status from './components/Status';
import MercadoPagoOauthTokenHook from './hooks/MercadoPagoOauthTokenHook';

export default function MercadoPagoOauthTokenPage() {

    // Get query params
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');

    const { isLoading, status } = MercadoPagoOauthTokenHook(code, state);

    return (
        <Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
            {
                isLoading
                ? <Flex justifyContent='center' alignItems='center' height='100vh'>
                    <Spinner />
                </Flex>
                : <Status status={status} />
            }
            
        </Box>
    );
}