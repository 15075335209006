import { apiFetch } from "services/api";

export async function postUserMenuItemApi(userId: number, menuItem: string): Promise<any> {
    const token = sessionStorage.getItem('token');

    const body = {
        user: userId,
        item: menuItem
    }

    const response = await apiFetch(
        '/auth/empresas/staff/menu-items',
        {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            },
            body: JSON.stringify(body)
        }
    );

    if (!response.ok) {
        throw new Error('Error al agregar item de menu');
    }
}