import { Box } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import VenueForm from "../../components/VenueForm";

export default function UpdateVenuePage() {

    const location = useLocation();
    const venue = location.state?.venue; // Access the venue object passed through navigate

    return (
        <Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
            <VenueForm venue={venue} />
        </Box>
    )
}