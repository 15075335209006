import { apiFetch } from "services/api";

export async function postCompanyUserApi(formData: FormData): Promise<any> {
    const token = sessionStorage.getItem('token');

    const response = await apiFetch(
        '/auth/empresas/staff',
        {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            },
            body: JSON.stringify(Object.fromEntries(formData))
        }
    );

    const data = await response.json();

    return data;
}