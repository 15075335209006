import { useCallback, useState } from "react";
import { generateMercadoPagoOauthUrl } from "utils/MercadoPagoOauth";
import { fetchMercadoPagoTokenStatus } from "../services/fetchMercadoPagoTokenStatus";
import { loginUser } from "../services/loginUser";

export default function useAuthHook() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [isMercadoPagoTokenValid, setIsMercadoPagoTokenValid] = useState<boolean>(false);
    const [mercadoPagoOauthUrl, setMercadoPagoOauthUrl] = useState<string>("");

    const login = useCallback(async (username: string, password: string) => {
        setIsLoading(true);

        try {
            const loginResponse = await loginUser(username, password);

            if (loginResponse.status === 200) {
                const responseData = await loginResponse.json();

                setSessionStorageValues(responseData);
                
                const mercadoPagoTokenResponse = await fetchMercadoPagoTokenStatus();

                // parse response into json
                const response = await mercadoPagoTokenResponse.json();

                const countCondition = response['count'] > 0;
                // Check if at least 1 token has is_active as true
                const atLeastOneTokenActiveCondition = response['results'].some((token: any) => token['is_active'] === true);
                
                if (countCondition && atLeastOneTokenActiveCondition) {
                    setIsMercadoPagoTokenValid(true);
                } else {
                    const mercadoPagoOauthUrl: string = generateMercadoPagoOauthUrl('PRINCIPAL');
                    setMercadoPagoOauthUrl(mercadoPagoOauthUrl);
                }

                sessionStorage.setItem('isMercadoPagoTokenValid', (mercadoPagoTokenResponse.status === 200) ? 'true' : 'false');
                
                setIsAuthenticated(true);
            } else if (loginResponse.status === 400){
                setError("Usuario o contraseña incorrectos");
            } else {
                setError("Estamos teniendo problemas, intente nuevamente más tarde");
            }
        } catch (error) {
            setError("Estamos teniendo problemas, intente nuevamente más tarde");
        } finally {
            setIsLoading(false);
        }
    }, []);

    return { isLoading, error, isAuthenticated, isMercadoPagoTokenValid, login, mercadoPagoOauthUrl };
}

function setSessionStorageValues(responseData: any) {
    sessionStorage.setItem('token', responseData.token);
    sessionStorage.setItem('empresaId', responseData.empresa_id);
    sessionStorage.setItem('userType', responseData.user_type);
    const items = [
        'EVENTOS',
        'USUARIOS',
        'REPORTES',
        'PREDIOS',
        'PRODUCTOS',
        'FINANZAS'
    ];
    items.forEach(item => {
        if (responseData.menu_items.includes(item)) {
        sessionStorage.setItem(`can${item}`, 'true');
        } else {
        sessionStorage.setItem(`can${item}`, 'false');
        }
    });
}
    

