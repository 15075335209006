import { useState } from 'react';
import { postProductApi } from '../create/services/postProductApi';

const useFormSubmit = (type: string, id?: number) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const submitForm = async (formData: FormData) => {
        setLoading(true);
        try {
            if (type === 'create') {
                await postProductApi(formData);
            } else if (type === 'update') {
                // await putProductApi(id, formData);
            }
            setLoading(false);
            setError(null);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    return { submitForm, loading, error };
}

export default useFormSubmit;