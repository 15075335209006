import { apiFetch } from "../../../../services/api";
import { EventSummary } from "../models/EventSummary";

export async function fetchEvents(): Promise<EventSummary[]>{
    // Get token from sessionStorage
    const token = sessionStorage.getItem('token');

    // Fetch from api and pass token for authentication
    const response = await apiFetch(
        '/dashboard/eventos',
        {
            headers: {
                "Authorization": `Token ${token}`
        }
    });

    const data = await response.json();

    // for loop to match snake case to camel case
    for (let i = 0; i < data.length; i++) {
        data[i].eventStartDatetimeUtc = data[i].event_start_datetime_utc;
        data[i].eventEndDatetimeUtc = data[i].event_end_datetime_utc;
        data[i].entradasVendidas = data[i].entradas_vendidas;
        data[i].entradasTotales = data[i].entradas_totales;

        delete data[i].event_start_datetime_utc;
        delete data[i].event_end_datetime_utc;
        delete data[i].entradas_vendidas;
        delete data[i].entradas_totales;
    }


    // Parse data to EventSummary[]
    return data as EventSummary[];
}