import { apiFetch } from "services/api";
import Product from "../../../models/Product";


export async function fetchProduct(id: number): Promise<Product>{
    // Get token from sessionStorage
    const token = sessionStorage.getItem('token');

    // Fetch from api and pass token for authentication
    const response = await apiFetch(
        `/products/${id}`,
        {
            headers: {
                "Authorization": `Token ${token}`
            }
        });

    const data = await response.json();

    const product: Product = {
        id: data.id,
        category: data.category,
        name: data.name,
        description: data.description,
        image: data.image,
        price: data.price,
        stock: data.stock,
        quantity: data.quantity,
        minimumAge: data.minimum_age,
    }

    return product;
}