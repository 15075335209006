import { apiFetch } from "../../../../../../services/api";
import Event from "../../models/Event";

export async function fetchEvent(
    id: number
): Promise<Event>{
    try {
        // Get token from sessionStorage
        const token = sessionStorage.getItem('token');

        // Fetch from api and pass token for authentication
        const response = await apiFetch(
            `/eventos/${id}`,
            {
                headers: {
                    "Authorization": `Token ${token}`
            }
        });

        if (!response.ok){
            throw new Error(response.statusText);
        }

        var data = await response.json();

        // Parse data into Event
        const event: Event = {
            id: data.id,
            nombre: data.nombre,
            habilitado: data.habilitado,
            eventStartDatetimeUtc: data.event_start_datetime_utc,
            eventEndDatetimeUtc: data.event_end_datetime_utc,
            edad: data.edad,
            desc: data.desc,
            imgPoster: data.img_poster,
            imgHeader: data.img_header,
            hasWaitingRoom: data.has_waiting_room === "true" ? true : false,
            predio: data.predio,
            categoria: data.categoria,
        };

        return event;
        
    } catch (error) {
        return null;
    }
}