import { apiFetch } from "../../../../../../services/api";
import Promotion from "../../models/Promotion";

export async function fetchPromotions(
    eventId: number
): Promise<Promotion[]>{
    // Get token from sessionStorage
    const token = sessionStorage.getItem('token');

    // Fetch from api and pass token for authentication
    const response = await apiFetch(
        `/ecommerce/promotions?event_id=${eventId}`,
        {
            headers: {
                "Authorization": `Token ${token}`
        }
    });

    const data = await response.json();

    return data as Promotion[];
}