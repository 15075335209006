import { Provincia } from "../models/Provincia";

export async function fetchProvinciasApi(): Promise<Provincia[]> {
    const response = await fetch(
        'https://apis.datos.gob.ar/georef/api/provincias?campos=nombre'
    );

    const data = await response.json();

    const provincias = data.provincias as Provincia[];

    return provincias;
}