import { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import 'assets/css/MiniCalendar.css';
import { Text, Icon } from '@chakra-ui/react';
// Chakra imports
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
// Custom components
import Card from 'components/card/Card';

export interface CalendarProps {
	selectRange: boolean;
	onValueChange: (date: Date) => void;
	initialDate: Date;
	[x: string]: any;
}

export default function MiniCalendar(props: CalendarProps) {

	const { selectRange, onValueChange, initialDate, ...rest } = props;

	const initialDateObj = initialDate ? new Date(initialDate) : new Date();

    const [date, setDate] = useState(initialDateObj || new Date());


	return (
		<Card
			alignItems='center'
			flexDirection='column'
			w='100%'
			maxW='max-content'
			p='20px 15px'
			h='max-content'
			{...rest}>
			<Calendar
				onChange={(date: Date) => {
					setDate(date);
					onValueChange(date);
				}}
				value={date}
				selectRange={selectRange}
				view={'month'}
				tileContent={<Text color='brand.500' />}
				prevLabel={<Icon as={MdChevronLeft} w='24px' h='24px' mt='4px' />}
				nextLabel={<Icon as={MdChevronRight} w='24px' h='24px' mt='4px' />}
			/>
		</Card>
	);
}
