import { Button, Flex, FormControl, FormLabel, Input, InputGroup, InputLeftAddon, NumberInput, NumberInputField, Spinner, useColorModeValue, useToast } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { ErrorMessage, Field, FieldArray, FieldProps, Form, Formik } from 'formik';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import InviteHook from '../hooks/InviteHook';


interface InviteFormProps {
    eventId: string;
}

export default function InviteForm(props: InviteFormProps) {
    const { eventId } = props;

    const toast = useToast();
    
    const navigate = useNavigate();
    
    const textColor = useColorModeValue("navy.700", "white");

    const { isLoading, tickets, submitForm, error, submitted } = InviteHook(parseInt(eventId));

    useEffect(() => {
        if (error && !isLoading) {
            toast({
                title: 'Error al crear la invitación',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top-right'
            });
        } else if (submitted && !isLoading) {
            toast({
                title: 'Invitación creada exitosamente',
                status: 'success',
                duration: 3000,
                isClosable: true,
                position: 'top-right'
            });
            navigate(`/admin/eventos/${eventId}`);
        }
    }, [error, eventId, isLoading, navigate, submitted, toast]);

    return (
        isLoading
        ? <Flex justifyContent='center' alignItems='center' height='100vh'>
            <Spinner />
        </Flex>
        : <Card>
            <Formik
                initialValues={{
                    phone: '',
                    event_id: eventId,
                    tickets: tickets.map(ticket => ({ ticket_id: ticket.id, quantity: 0 }))
                }}
                onSubmit={async values => {
                    values.phone = `+54${values.phone}`;
                    values.tickets = values.tickets.filter(ticket => Number(ticket.quantity) > 0);
                    await submitForm(values);
                }}
            >
                {(props) => (
                    <Form>
                        <Field name='phone'>
                            {({ field, form }: FieldProps) => (
                                <FormControl>
                                    <FormLabel htmlFor='phone'>Celular</FormLabel>
                                    <InputGroup>
                                        <InputLeftAddon
                                            children='+54'
                                            fontSize='sm'
                                            fontWeight='500'
                                            color={textColor}
                                        />
                                        <Input {...field} id='phone'/>
                                    </InputGroup>
                                </FormControl>
                            )}
                        </Field>
                        <FieldArray name="tickets">
                            {({ push, remove }) => (
                                tickets.map((ticket, index) => (
                                    <Field name={`tickets[${index}].quantity`} type="number" min="1">
                                        {({ field, form }: FieldProps) => (
                                            <FormControl>
                                                <FormLabel htmlFor={`tickets[${index}].quantity`}>Elige cuantas {ticket.tipo}</FormLabel>
                                                <NumberInput min={1} max={ticket.cantidad}>
                                                    <NumberInputField {...field} id={`tickets[${index}].quantity`} placeholder={`Quedan: ${ticket.cantidad}`} />
                                                </NumberInput>
                                                <ErrorMessage name={`tickets[${index}].quantity`} />
                                            </FormControl>
                                        )}
                                    </Field>
                                ))
                            )}
                        </FieldArray>
                        <Button
                            type='submit'
                            isLoading={isLoading}
                            colorScheme='brand'
                            size='lg'
                            fontSize='md'
                            fontWeight='500'
                            borderRadius='15px'
                            mt='1rem'
                            mx={{ base: "auto", lg: "unset" }}
                            me='auto'
                            mb={{ base: "20px", md: "auto" }}>
                            Confirmar
                        </Button>
                    </Form>
                )}
            </Formik>
        </Card>
    )
}