export async function apiFetch(endpoint: string, options: RequestInit = {}) {

  const API_URL = process.env.REACT_APP_API_URL;

  const response = await fetch(`${API_URL}${endpoint}`, {
    ...options,
    headers: {
      ...options.headers,
    },
  });

  // if (!response.ok) {
  //   throw new Error(`API request failed: ${response.statusText}`);
  // }
  return response;
}