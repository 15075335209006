import { useState } from "react";
import {
    Box,
    Button,
    Flex,
    Icon,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useMediaQuery,
    Badge
} from '@chakra-ui/react';
import {
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable
} from '@tanstack/react-table';
import { MdCancel, MdCheckCircle } from 'react-icons/md';
import Card from 'components/card/Card';
import { useNavigate } from "react-router-dom";
import { CompanyUser } from "../models/CompanyUser";


interface CompanyUsersTableProps {
    companyUsers: CompanyUser[];
}

const columnHelper = createColumnHelper<CompanyUser>();

export default function CompanyUsersTable (props: CompanyUsersTableProps) {
    const navigate = useNavigate();
	const { companyUsers } = props;
	const [ sorting, setSorting ] = useState<SortingState>([]);
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
    const [isMobile] = useMediaQuery("(max-width: 480px)");

    // Generate color for each category (tipo) -> ORGANIZADOR: green, SEGURIDAD: grey, BARMAN: blue
    const categoryColor = (tipo: string) => {
        switch (tipo) {
            case 'ORGANIZADOR':
                return 'green';
            case 'SEGURIDAD':
                return 'blue';
            case 'BARTENDER':
                return 'orange';
            default:
                return 'gray';
        }
    }
    
    const columns = [
		columnHelper.accessor('tipo', {
			id: 'tipo',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Categoria
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Badge color={categoryColor(info.getValue())} fontSize='sm' fontWeight='700'>
						{info.getValue()}
					</Badge>
				</Flex>
			)
		}),
		columnHelper.accessor('description', {
			id: 'description',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Nombre
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Text color={textColor} fontSize='sm' fontWeight='700'>
						{info.getValue()}
					</Text>
				</Flex>
			)
		}),
        columnHelper.accessor('user', {
			id: 'phone',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Celular
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Text color={textColor} fontSize='sm' fontWeight='700'>
						{info.getValue().username.split('_')[1]}
					</Text>
				</Flex>
			)
		}),
        columnHelper.accessor('user', {
			id: 'is_active',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Activo
				</Text>
			),
			cell: (info: any) => (
				<Icon
					w='24px'
					h='24px'
					me='5px'
					color={
						info.getValue()
						? 'green.500'
						: 'red.500'
					}
					as={
						info.getValue().is_active
						? MdCheckCircle
						: MdCancel
					}
				/>
			)
		}),
		// // Show a button to get more details of the event
        // columnHelper.accessor('id', {
        //     id: 'editCompanyUser',
        //     header: () => (
        //         <Text
        //         justifyContent='space-between'
        //         align='center'
        //         fontSize={{ sm: '10px', lg: '12px' }}
        //         color='gray.400'>
                
        //         </Text>
        //     ),
        //     cell: (info) => {
        //         const eventId = info.getValue();
            
        //         return (
		// 			<Button
		// 				variant='brand'
		// 				fontSize='sm'
		// 				onClick={() => {
		// 					navigate(`/admin/usuarios/${eventId}/editar`);
		// 					}
		// 				}
		// 			>
		// 				Editar
		// 			</Button>
        //         );
        //     }
        // }),
    ];

    const table = useReactTable({
		data: companyUsers,
		columns,
		state: {
			sorting
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		debugTable: true
	});
    return (
        <Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
				<Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
					Mis usuarios
				</Text>
                <Button
                    variant='brand'
                    fontSize='sm'
                    onClick={() => navigate('/admin/usuarios/crear')
                    }
                >
                    {isMobile ? "+" : "+ Nuevo usuario"}
                </Button>
			</Flex>
			<Box>
				<Table variant='simple' color='gray.500' mb='24px' mt="12px">
					<Thead>
						{table.getHeaderGroups().map((headerGroup) => (
							<Tr key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									return (
										<Th
											key={header.id}
											colSpan={header.colSpan}
											pe='10px'
											borderColor={borderColor}
											cursor='pointer'
											onClick={header.column.getToggleSortingHandler()}>
											<Flex
												justifyContent='space-between'
												align='center'
												fontSize={{ sm: '10px', lg: '12px' }}
												color='gray.400'>
												{flexRender(header.column.columnDef.header, header.getContext())}{{
													asc: '',
													desc: '',
												}[header.column.getIsSorted() as string] ?? null}
											</Flex>
										</Th>
									);
								})}
							</Tr>
						))}
					</Thead>
					<Tbody>
						{table.getRowModel().rows.slice(0, 11).map((row) => {
							return (
								<Tr key={row.id}>
									{row.getVisibleCells().map((cell) => {
										return (
											<Td
												key={cell.id}
												fontSize={{ sm: '14px' }}
												minW={{ sm: '150px', md: '200px', lg: 'auto' }}
												borderColor='transparent'>
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</Td>
										);
									})}
								</Tr>
							);
						})}
					</Tbody>
				</Table>
			</Box>
		</Card>
    );
}