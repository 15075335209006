import {
    Button,
    Divider,
    Flex,
    Icon,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
import { MdAdd } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import Card from 'components/card/Card';
import { Invite } from "../../models/Invite";
import InviteItem from './InviteItem';

interface InvitesListProps {
    invites: Invite[];
}

export default function  InvitesList(props: InvitesListProps) {
    
    const { invites } = props;

    const { id } = useParams();

    const navigate = useNavigate();

    const textColor = useColorModeValue('secondaryGray.900', 'white');
	const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
	const bgHover = useColorModeValue({ bg: 'secondaryGray.400' }, { bg: 'whiteAlpha.50' });
	const bgFocus = useColorModeValue({ bg: 'secondaryGray.300' }, { bg: 'whiteAlpha.100' });
    
    return (
        <Card alignItems='center' flexDirection='column' w='100%' mb="1rem">
            <Flex
                align={{ sm: 'flex-start', lg: 'center' }}
                justify='space-between'
                w='100%'
                px='22px'
                py='18px'>
                <Text color={textColor} fontSize='xl' fontWeight='600'>
                    Lista de invitaciones
                </Text>

                <Button
                    alignItems='center'
                    justifyContent='center'
                    bg={bgButton}
                    _hover={bgHover}
                    _focus={bgFocus}
                    _active={bgFocus}
                    w='37px'
                    h='37px'
                    lineHeight='100%'
                    borderRadius='10px'
                    mr='10px'
                    onClick={() => {
                        navigate(`/admin/eventos/${id}/invitaciones/crear`);
                    }}
                    >
                    <Icon as={MdAdd} />
                </Button>
            </Flex>
            <Divider />
            {invites.map((invite: Invite, index: number) => (
                <InviteItem key={index} invite={invite}/>
            ))}
        </Card>
    )
}