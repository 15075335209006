// Chakra imports
import {
	Box,
	Flex,
	Text,
	useColorModeValue
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import PieChart from 'components/charts/PieChart';
import { VSeparator } from 'components/separator/Separator';

export interface ConversionProps {
	title: string;
	data: number[];
	labels: any[];
	[x: string]: any;
}

export default function Conversion(props: ConversionProps) {
	const { title, data, labels, ...rest } = props;

	// Chakra Color Mode
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const cardColor = useColorModeValue('white', 'navy.700');
	const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');

	const pieChartOptions = getChartOptions(labels);

	// Calculate total
	const totalCount = data.reduce((a, b) => a + b, 0);

	// Calculate percentage
	const percentages = data.map((item) => `${((item / totalCount) * 100).toFixed(2)}%`);

	return (
		<Card p='20px' alignItems='center' flexDirection='column' w='100%' {...rest}>
			<Flex
				px={{ base: '0px', '2xl': '10px' }}
				justifyContent='space-between'
				alignItems='center'
				w='100%'
				mb='8px'>
				<Text color={textColor} fontSize='md' fontWeight='600' mt='4px'>
					{title}
				</Text>
				{/* <Select fontSize='sm' variant='subtle' defaultValue='monthly' width='unset' fontWeight='700'>
					<option value='daily'>Daily</option>
					<option value='monthly'>Monthly</option>
					<option value='yearly'>Yearly</option>
				</Select> */}
			</Flex>
			{
				(data.length === 0)
				? <Flex justifyContent='center' alignItems='center' h='100%'>
					<Text color={textColor} fontSize='xl' fontWeight='700' lineHeight='100%'>
						No hay datos para mostrar.
					</Text>
				</Flex>
				: <PieChart h='100%' w='100%' chartData={data} chartOptions={pieChartOptions} />
			}
			<Card
				bg={cardColor}
				flexDirection='row'
				boxShadow={cardShadow}
				w='100%'
				p='15px'
				px='20px'
				mt='15px'
				mx='auto'>
				<Flex direction='column' py='5px'>
					<Flex align='center'>
						<Box h='8px' w='8px' bg='brand.500' borderRadius='50%' me='4px' />
						<Text fontSize='xs' color='secondaryGray.600' fontWeight='700' mb='5px'>
							Masculino
						</Text>
					</Flex>
					<Text fontSize='lg' color={textColor} fontWeight='700'>
						{percentages[0]}
					</Text>
				</Flex>
				<VSeparator mx={{ base: '60px', xl: '60px', '2xl': '60px' }} />
				<Flex direction='column' py='5px' me='10px'>
					<Flex align='center'>
						<Box h='8px' w='8px' bg='#6AD2FF' borderRadius='50%' me='4px' />
						<Text fontSize='xs' color='secondaryGray.600' fontWeight='700' mb='5px'>
							Femenino
						</Text>
					</Flex>
					<Text fontSize='lg' color={textColor} fontWeight='700'>
						{percentages[1]}
					</Text>
				</Flex>
				<VSeparator mx={{ base: '60px', xl: '60px', '2xl': '60px' }} />
				<Flex direction='column' py='5px' me='10px'>
					<Flex align='center'>
						<Box h='8px' w='8px' bg='#6AD2FF' borderRadius='50%' me='4px' />
						<Text fontSize='xs' color='secondaryGray.600' fontWeight='700' mb='5px'>
							Otro/s
						</Text>
					</Flex>
					<Text fontSize='lg' color={textColor} fontWeight='700'>
						{percentages[2]}
					</Text>
				</Flex>
			</Card>
		</Card>
	);
}

function getChartOptions(labels: any[]) {
	return {
		labels: labels,
		colors: [ '#4318FF', '#6AD2FF', '#0c2382' ],
		chart: {
			width: '50px'
		},
		states: {
			hover: {
				filter: {
					type: 'none'
				}
			}
		},
		legend: {
			show: false
		},
		dataLabels: {
			enabled: false
		},
		hover: { mode: null  as any},
		plotOptions: {
			donut: {
				expandOnClick: false,
				donut: {
					labels: {
						show: false
					}
				}
			}
		},
		fill: {
			colors: [ '#4318FF', '#6AD2FF', '#0c2382' ],
		},
		tooltip: {
			enabled: true,
			theme: 'dark'
		}
	};
}