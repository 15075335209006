import {
    FormControl,
    FormLabel,
    Input,
    Select,
    Button,
    NumberInput,
    NumberInputField,
    useToast,
    Textarea,
    InputGroup,
    useColorModeValue,
    InputLeftAddon,
    CheckboxGroup,
    Checkbox,
    Divider,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { Form, Formik, Field, FieldProps, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useFormSubmit from "views/admin/users/hooks/useFormSubmit";
import { CompanyUser } from "views/admin/users/models/CompanyUser";

interface CompanyUserFormProps {
    companyUser?: CompanyUser;
    [x: string]: any;
}

interface FormValues {
    [key: string]: any;
    username: string;
    description: string;
    tipo: string;
    password: string;
    password2: string;
    menuItems: string[];
}

export default function CompanyUserForm(props: CompanyUserFormProps) {

    const textColor = useColorModeValue("navy.700", "white");
    const toast = useToast();
    const navigate = useNavigate();
    const { submitForm, loading, error, submitted } = useFormSubmit();
    const [selectedCategory, setSelectedCategory] = useState<string>('');

    useEffect(() => {
        if (error && !loading) {
            toast({
                title: 'Error al crear el usuario',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top-right'
            });
        } else if (!error && !loading && submitted) {
            toast({
                title: 'Usuario creado exitosamente',
                status: 'success',
                duration: 3000,
                isClosable: true,
                position: 'top-right'
            });
            // redirect 
            navigate('/admin/usuarios');
        }
    }, [error, loading, navigate, submitted, toast]);
    
    const initialValues: FormValues = {
        username: '',
        description: '',
        tipo: '',
        password: '',
        password2: '',
        menuItems: []
    };

    const companyUserTypes = ['ORGANIZADOR', 'SEGURIDAD', 'BARTENDER'];

    const validatePasswords = (values: FormValues) => {
        const errors: Partial<FormValues> = {};
    
        if (!values.password) {
            errors.password = 'La contraseña es requerida';
        // check if password has 8 characters
        } else if (values.password.length < 8) {
            errors.password = 'La contraseña debe tener al menos una letra mayúscula, un símbolo y un número';
        }
    
        if (!values.password2) {
            errors.password2 = 'La verificación de la contraseña es requerida';
        } else if (values.password !== values.password2) {
            errors.password2 = 'Las contraseñas no coinciden';
        }
    
        return errors;
    }

    const onSubmit = async (values: FormValues) => {
        const userData = new FormData();
        userData.append('username', `empresa_+54${values.username}`);
        userData.append('password', values.password);
        userData.append('password2', values.password2);
        userData.append('phone', `+54${values.username}`);
        userData.append('group', 'empresa');
        
        const companyUserData = new FormData();
        companyUserData.append('description', values.description);
        companyUserData.append('tipo', values.tipo);

        const menuItems = values.menuItems;
        
        await submitForm(userData, companyUserData, menuItems);
    }
    return (
        <Card>
            <Formik
                initialValues = {initialValues}
                onSubmit = {onSubmit}
                validate = {validatePasswords}
            >
                {(props) => (
                    <Form>
                        <Field name='username'>
                            {({ field, form }: FieldProps) => (
                                <FormControl isRequired={ true } mb="1rem">
                                    <FormLabel htmlFor='username'>Celular</FormLabel>
                                    <InputGroup>
                                    <InputLeftAddon
                                        children='+54'
                                        fontSize='sm'
                                        fontWeight='500'
                                        color={textColor}
                                        />
                                        <Input {...field} id='username' />
                                    </InputGroup>
                                </FormControl>
                            )}
                        </Field>
                        <Field name='description'>
                            {({ field, form }: FieldProps) => (
                                <FormControl isRequired={ true } mb="1rem">
                                    <FormLabel htmlFor='description'>Nombre</FormLabel>
                                    <Input {...field} id='description' maxLength={128} />
                                </FormControl>
                            )}
                        </Field>
                        <Field name='tipo'>
                            {({ field, form }: FieldProps) => (
                                <FormControl isRequired={ true } mb="1rem">
                                    <FormLabel htmlFor='category'>Categoria</FormLabel>
                                    <Select
                                        id='category'
                                        placeholder='Seleccione un categoria'
                                        value={field.value}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            form.setFieldValue(field.name, value);
                                            setSelectedCategory(value);
                                          }}
                                    >
                                        {
                                            companyUserTypes.map((companyUserType: string) => (
                                                <option value={companyUserType}>{companyUserType}</option>
                                                ))
                                            }
                                    </Select>
                                </FormControl>
                            )}
                        </Field>
                       {selectedCategory === 'ORGANIZADOR' && (
                         <Field name='menuItems'>
                            {({ field }: FieldProps) => (
                                <FormControl mb="1rem">
                                    <FormLabel htmlFor='menuItems'>Funciones</FormLabel>
                                    <CheckboxGroup
                                        value={field.value}
                                        onChange={(value) => {
                                            field.onChange({ target: { name: field.name, value } });
                                        }}
                                    >
                                        <Checkbox value='REPORTES'>Reportes</Checkbox>
                                        <Checkbox value='EVENTOS'>Eventos</Checkbox>
                                        <Checkbox value='USUARIOS'>Usuarios</Checkbox>
                                        <Checkbox value='PREDIOS'>Predios</Checkbox>
                                        <Checkbox value='PRODUCTOS'>Productos</Checkbox>
                                        <Checkbox value='FINANZAS'>Finanzas</Checkbox>
                                    </CheckboxGroup>
                                </FormControl>
                            )}
                        </Field>
                        )}
                        <Field name='password'>
                            {({ field, form }: FieldProps) => (
                                <FormControl isRequired={ true } mb="1rem">
                                    <FormLabel htmlFor='password'>Contraseña</FormLabel>
                                    <Input {...field} id='password' type='password' />
                                    <ErrorMessage name='password'>
                                        {msg => <div style={{ color: 'red' }}>{msg}</div>}
                                    </ErrorMessage>
                                </FormControl>
                            )}
                        </Field>
                        <Field name='password2'>
                            {({ field, form }: FieldProps) => (
                                <FormControl isRequired={ true } mb="1rem">
                                    <FormLabel htmlFor='password2'>Verificar contraseña</FormLabel>
                                    <Input {...field} id='password2' type='password' />
                                    <ErrorMessage name='password2'>
                                        {msg => <div style={{ color: 'red' }}>{msg}</div>}
                                    </ErrorMessage>
                                </FormControl>
                            )}
                        </Field>
                        <Button
                            type='submit'
                            // isLoading={loading}
                            colorScheme='brand'
                            size='lg'
                            fontSize='md'
                            fontWeight='500'
                            borderRadius='15px'
                            mx={{ base: "auto", lg: "unset" }}
                            me='auto'
                            mb={{ base: "20px", md: "auto" }}>
                            Crear
                        </Button>

                    </Form>
                )}
            </Formik>
        </Card>
    );
}