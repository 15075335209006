import { useState } from 'react';
import { deleteInviteApi } from '../services/deleteInviteApi';

export default function useDeleteTicket(id: number) {
    const [deleted, setDeleted] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState(null);

    const deleteInvite = async (id: number) => {
        setIsLoading(true);
        deleteInviteApi(id)
            .catch((error: any) => {
                setError(error);
            })
            .finally(() => {
                setDeleted(true);
                setIsLoading(false);
            });
    };

    return { deleteInvite, deleted, isLoading, error };
}